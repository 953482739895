.sub-navigation {
  display: flex;
  margin-top: map-get($spacers, 2);
  padding-top: map-get($spacers, 5);
  padding-bottom: map-get($spacers, 5);

  @include horizontal-scroll;

  &__item__inner {
    // font-size: rem($nav-font-size) * .75; // % from main nav size
    color: $text-muted;
    text-decoration: none;

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }

    &--active {
      color: $black;
    }
  }
}
