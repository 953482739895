.contact-office {
  padding: 0 0 map-get($spacers, 7);
  margin: 0 auto;
  max-width: calc(100vw - #{map-get($spacers, 5)});

  @include media-breakpoint-down(sm) {
    max-width: 230px;
    padding-top: map-get($spacers, 3);
  }

  @include media-breakpoint-between(md,lg) {
    margin-top: -#{map-get($spacers, 2)};
  }

  // @include media-breakpoint-between(md, md) {
  //   max-width: 720px;
  // }

  // @media (min-width: 960px) and  (max-width: 1025px) {
  //   max-width: 975px;
  // }

  &__contact__wrapper,
  &__office {
    // @include media-breakpoint-down(sm) {
    padding-bottom: map-get($spacers, 3);
    // }
  }

  &__office,
  &__contact {
    @include media-breakpoint-up(sm) {
      flex: 0 0 50%;
    }
  }

  &__office {
    @include media-breakpoint-between(lg, lg) {
      // @media (min-width: 960px) and  (max-width: 1025px) {
      flex: 0 0 35%;
    }

    @include media-breakpoint-up(xl) {
      flex: 0 0 33%;
    }

    .office-contact-card {
      // @include media-breakpoint-down(sm) {
      padding-top: map-get($spacers, 3);

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__contact {
    @include media-breakpoint-up(xl) {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 67%;
    }

    @include media-breakpoint-between(md, md) {
      //@media (min-width: 960px) and  (max-width: 1025px) {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 50%;

      &__wrapper {
        flex: 0 0 100%;
        width: 100%;
      }
    }

    @include media-breakpoint-between(lg, lg) {
      flex: 0 0 65%;
      display: flex;
      flex-wrap: wrap;

      &__wrapper {
        flex: 0 0 50%;
        width: 50%;
      }
    }

    @include media-breakpoint-up(xl) {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;

      &__wrapper {
        flex: 0 0 25%;
      }
    }
  }

  &__adress {
    white-space: break-spaces;
  }

  &__title {
    font-size: inherit;
    line-height: inherit;
  }

  &__contact__wrapper {
    &:last-of-type {
      padding-bottom: 0;
    }

    @include media-breakpoint-down(lg) {
      &.order--general-inquiries,
      &.order--0 {
        display: none;
      }
    }
  }

  &__list {
    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }
}
