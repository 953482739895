.news-list-card {
  position: relative;

  &__wrapper {
    display: flex;
    padding: map-get($spacers, 1) 0;
  }

  &__date {
    padding-right: map-get($spacers, 2);
    width: 140px;

    @include media-breakpoint-up(lg) {
      // padding-right: map-get($spacers, 5);
    }
  }

  &__title {
    flex: 1;
    // white-space: nowrap;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;

    // @include media-breakpoint-up(lg) {
    //   padding-left: map-get($spacers, 5);
    // }
  }

  &__title,
  &__date {
    font-weight: normal;
    color: gray-color(500);
  }

  @media (hover: hover) {
    &:hover {
      .news-list-card__title,
      .news-list-card__date {
        color: $black;
      }
    }
  }
}
