.project-text {
  // @include media-breakpoint-down(sm) {
  //   @include get-font-size(medium-text-m);
  //   @include get-line-height(medium-text-m);
  // }

  // @include media-breakpoint-up(xxl) {
  //   @include get-font-size(medium-text-l);
  //   @include get-line-height(medium-text-l);
  // }

  max-width: 450px;

  @include media-breakpoint-between(md, md) {
    max-width: 490px;
  }

  @include media-breakpoint-between(lg, lg) {
    max-width: 450px;
    margin-left: 0;
  }

  @include media-breakpoint-between(xl, xl) {
    max-width: 480px;
    margin: 0 auto;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 610px;
  }

  &::after {
    content: '227';
    display: none;
    opacity: 0;

    @include media-breakpoint-between(sm, md) {
      content: '227';
    }

    @include media-breakpoint-between(md, lg) {
      content: '501';
    }

    @include media-breakpoint-up(xl) {
      content: '757';
    }
  }

  &__content.open--detail {
    animation: fade-in .3s ease-in-out both;
  }

  @include media-breakpoint-down(md) {
    margin: 0 auto;
  }

  @include media-breakpoint-between(xl, xl) {
    // padding-right: map-get($spacers, 3);
  }
}
