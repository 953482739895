.people-content-block {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(xl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-auto-flow: dense;
    grid-column-gap: map-get($spacers, 2);
    grid-row-gap: map-get($spacers, 2);
  }

  &__image {
    position: relative;

    .image-caption {
      position: relative;
      text-align: left;
    }
  }

  &__main {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-between(md, lg) {
      max-width: 650px;
      margin: 0 auto;
    }

    @include media-breakpoint-between(md, md) {
      max-width: 500px;
    }

    // @include media-breakpoint-between(md, xxl) {
    //   max-width: 480px;
    // }

    @include media-breakpoint-between(xl, xxl) {
      max-width: 480px;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 610px;
    }
  }

  &__text {
    @extend .spacer-bottom-m;

    margin-bottom: 0;
  }

  &__images {
    @include media-breakpoint-down(sm) {
      margin-left: -#{map-get($spacers, 2)};
      margin-right: -#{map-get($spacers, 2)};
    }

    @include media-breakpoint-between(md, lg) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-auto-flow: dense;
      grid-column-gap: map-get($spacers, 2);
      grid-row-gap: map-get($spacers, 2);

      .people-content-block__image {
        padding-top: 66.73%;
        position: relative;
        overflow: hidden;

        img {
          @include abs(0,null, null,0);
          @include size(100%);

          object-fit: cover;
        }
      }
    }
  }

  &__quote {
    margin-bottom: 0;
    margin-top: auto;

    @include media-breakpoint-down(xl) {
      margin-top: map-get($spacers, 3);
    }

    blockquote {
      margin-bottom: 0;
    }
  }

  &__image {
    margin-bottom: 0;

    &.image--2 {
      @include media-breakpoint-down(sm) {
        margin-top: map-get($spacers, 3);
      }
    }

    img {
      width: auto;
      max-height: 93vh;
      object-fit: cover;

      @include media-breakpoint-up(md) {

      }

      @include media-breakpoint-up(xl) {
        min-height: 678px;
        max-width: 90%;
      }
    }
  }
}
