.image-modal {
  @include fxd(0,null, null, 0);
  @include size(100%, 100%);

  z-index: z-index(nav) + 10;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  fill: $white;
  color: $white;

  &--open {
    pointer-events: all;

    .image-modal__overlay,
    .image-modal__button,
    .image-modal__wrapper {
      transform: scale(1);
    }

    .image-modal__wrapper {
      transition: opacity .3s .2s ease-in-out;
      opacity: 1;
    }

    .image-modal__overlay {
      opacity: 1;
      transition: opacity .3s ease-in-out, transform .002s ease-in-out;
    }
  }

  &__button,
  &__overlay,
  &__wrapper {
    position: relative;
    transform: scale(0);
    z-index: 10;
  }

  &__wrapper {
    transition: opacity .3s ease-in-out;
    width: 100vw;
    opacity: 0;
    height: 100vh;

    // @include media-breakpoint-down(sm) {
    //   padding-top: 5vh;
    //   height: 68vh;
    //   width: 100vw;
    //   padding-left: map-get($spacers, 2);
    //   padding-right: map-get($spacers, 2);
    // }
  }

  &__overlay {
    @include abs(0,0,0,0);

    opacity: 0;
    z-index: 5;
    transition: opacity .3s .3s ease-in-out, transform .002s .6s ease-in-out;
    background-color: rgba($white, 1);
  }

  &__button {
    @include abs(map-get($spacers, 4),map-get($spacers, 3), null, null );

    .icon {
      @include size(30px);
    }

    @include media-breakpoint-down(sm) {
      right: map-get($spacers, 2);
      top: map-get($spacers, 2);

      .icon {
        transform: scale(.8);
      }
    }
  }

  &__content {
    position: relative;
    z-index: 6;
  }

  .image-modal__image {
    @include size(70vw, 100vh);

    text-align: center;
    display: flex;
    position: relative;
    max-width: 90vw;

    &::before {
      @include abs(50%, null, null, 50%);

      content: 'Loading image';
      font-weight: 600;
      text-transform: uppercase;
      color: gray-color(500);
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    @include media-breakpoint-down(sm) {
      @include size(100vw, 70vh);

      max-width: 100vw;
      margin: auto;
    }

    img {
      object-fit: contain;
      margin: auto;
      // opacity: 0;
      z-index: 20;

      @include size(100%);

      // margin-top: 2%;
    }

    figcaption {
      text-align: center;
      padding: map-get($spacers, 2) 0;
    }
  }

  .glide {
    @include size(80vw, 100vh);

    position: relative;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      @include size(100vw, 90vh);

      margin-top: 5vh;
      display: flex;
    }
  }

  .glide__track {
    @include media-breakpoint-down(sm) {
      margin: auto;
    }
  }

  .glide__arrow {
    // @include abs(100%, null, null, null);
    @include size(44px);
    // transform: translate(0, 0);
    font-size: 0;

    @include media-breakpoint-down(sm) {
      @include abs(auto, null, 0, 50%);
    }

    &--left {
      left: 0;
      transform: translateX(-110%);
      background: url('/assets/gfx/icon-chevron-light-left-black.svg') no-repeat center center;

      @include media-breakpoint-down(sm) {
        left: 50%;
        transform: translateX(-200%);
      }
    }

    &--right {
      right: 0;
      transform: translateX(110%);
      // transform: translate(0, 0);
      background: url('/assets/gfx/icon-chevron-light-right-black.svg') no-repeat center center;

      @include media-breakpoint-down(sm) {
        left: 50%;
        transform: translateX(100%);
      }
    }

    @media (hover: hover) {
      // opacity: 0;
      // background: none;
      // transform: translateX(0);
      // top: 70px;
      // cursor: none;

      // @include size(100%, calc(100% - 70px));

      // &--right {
      //   transform: translateX(50%);
      // }

      // &--left {
      //   transform: translateX(-50%);
      // }
    }
  }

  .glide__bullets {
    @include abs(null, null, 0, 50%);

    transform: translate(-50%, 150%);

    @include media-breakpoint-down(sm) {
      transform: translate(-50%, 200%);
    }
  }

  .slider__page {
    @extend .type-jumbo;

    @include abs(-#{map-get($spacers, 2)}, null, null, map-get($spacers, 3));

    display: block;

    @include media-breakpoint-down(sm) {
      top: -#{map-get($spacers, 3)};
      transform: translateY(5px);
      left: map-get($spacers, 2);
      // transform: scale(.8);
    }

    @include media-breakpoint-up(sm) {
      transform: translate(0, 50px);
      letter-spacing: -.2ex;
    }
  }

  .slider__cursor__pointer {
    background-image: url('/assets/gfx/icon-chevron-left-black.svg');
  }
}
