.request-form {
  padding: 0;
  padding-bottom: map-get($spacers, 2);

  // .press-snackbar__main__wrapper__text {
  //   margin-bottom: cm(1);
  // }

  .download-button {
    // map-get($spacers, 2)
  }

  &__message {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__text {
      // font-size: rem(18);
      text-align: center;
      padding: $spacer;
    }

    &__processing {
      padding: $spacer;
      margin-top: map-get($spacers, 2);
      background-color: color(white);
      color: color(black);
      font-size: 1.1em;
      position: relative;

      &::before {
        @include abs(0, null, null, 0);

        height: 100%;
        width: 100%;
        background: rgba(#000, .2);
        content: '';
      }

      &.set--loading::before {
        animation: process 1s linear infinite;
      }
    }
  }

  .type--submit {
    width: 100%;
    text-align: center;
    // font-family: $header-font;
    text-transform: uppercase;

    &:hover {
      background-color: $white;
      color: $black;
      fill: $black;
      border-color: $white;
    }
  }
}

@keyframes process {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
