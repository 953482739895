app-news-card {
  display: contents;
}

.news-card {
  @extend .aspect-ratio;

  border-bottom: 2px solid gray-color(200);
  width: 100%;
  height: 100%;

  &::before {
    padding-top: 90%;

    @include media-breakpoint-down(sm) {
      padding-top: 108%;
    }

    @include media-breakpoint-between(md, lg) {
      padding-top: 102%;
    }

    @include media-breakpoint-up(xxl) {
      padding-top: 75%;
    }
  }

  &__inner {
    @include size(100%);
    @include abs(0);

    > div {
      @include size(100%);

      display: flex;
      flex-direction: column;
    }
  }

  &__figure {
    height: 100%;
    margin-bottom: 0;

    .image-link {
      display: contents;
    }

    img {
      width: 100%;
      position: relative;
    }

    &::before {
      height: 100%;
      padding-top: 0;
      display: none;
    }

    .card__figure__read-more:hover {
      background-color: white;
      border-color: white;
      color: black;
    }
  }

  &__title {
    @extend .spacer-top-xs;
    @extend .type-body-small;

    text-transform: uppercase;

    span {
      &.text {
        // max-height: 60px;
        // overflow: hidden;
        // display: inline-block;
        // text-overflow: ellipsis;
        // display: box;
        // display: -webkit-box;
        // line-clamp: 3;
        // -webkit-line-clamp: 3; /* number of lines to show */
        // -webkit-box-orient: vertical;
      }

      &.text-muted {
        margin-left: 1ex;
      }
    }
  }
}
