@import 'icon-colors';


fstr-icon,
.icon {
  position: relative;
  display: inline-block;
  min-width: 14px;
  min-height: 14px;

  &-svg {
    @include abs(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    fill: inherit;
  }
}

fstr-icon {
  .icon {
    height: 100%;
    width: 100%;
  }
}

@each $name,
$value in $icon-sizes {
  .icon-#{$name} {
    height: $value;

    svg {
      @include size(rem($value));
    }
  }
}