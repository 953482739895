// TODO when font is bought by KCAP uncomment this
@font-face {
  $url: 'GT-America-Extended-Bold';

  font-family: 'GT America Extended Bold';
  font-style: normal;
  font-weight: bold;
  src: url('/assets/fonts/#{$url}.woff2') format('woff2'), url('/assets/fonts/#{$url}.woff') format('woff');
  // font-display: fallback;
  font-display: swap;
}

@font-face {
  $url: 'GT-America-Standard-Medium';

  font-family: 'GT America Medium';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/#{$url}.woff2') format('woff2'), url('/assets/fonts/#{$url}.woff') format('woff');
  // font-display: fallback;
  font-display: swap;
}
