// Get the font-size from our font-sizes map
// and pass it to Bootstrap's font-size mixin

@mixin get-font-size($key) {
  @if map-has-key($font-sizes, $key) {
    // @include font-size(map-get($font-sizes, $key));
    font-size: pt-to-px(map-get($font-sizes, $key));
    // $size: floor(map-get($font-sizes, $key)/1.333);

    // font-size: strip-units($size) * 1px;
  }

  @else {
    @warn 'Unknown `#{$key}` in $map.';
  }
}
