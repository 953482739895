.project-card {
  // padding-bottom: map-get($spacers, 1);
  position: relative;
  overflow: hidden;

  a {
    color: inherit;
  }

  &__title {
    max-height: 33px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    color: inherit;
    transition: opacity .2s;
    text-transform: none;
    // margin-bottom: 0;
    margin: map-get($spacers, 2) 0;
    // padding: map-get($spacers, 1) 0;

    @include media-breakpoint-up(md) {
      //  padding: map-get($spacers, 2) 0;
    }
  }

  // .title-link:hover .project-card__title {
  //   color: white;
  // }

  &__figure {
    overflow: hidden;
    margin: 0;

    &::before {
      display: none;
    }

    img {
      width: 100%;
      object-fit: cover;

      &.set--loaded {
        animation: fade-in .2s ease-in-out both;
      }
      // @extend .aspect-ratio-element;
    }
  }

  .card__figure__read-more:hover {
    color: black;
    border-color: white;
    background-color: white;
  }

  &__image {
    // filter: grayscale(100%);
    overflow: hidden;

    // transform: scale(1.1);
    // transition: transform .35s ease-in-out, filter .2s;
  }

  &__crop.no--image::before {
    background-color: rgba(white, .05);
  }

  &__crop::before {
    padding-top: 75.17%;
  }

  @media (hover: hover) {
    &:hover {
      .project-card__title {
        // color: white;
        opacity: 1;
      }
    }
  }
}
