.loading {
  @include fxd(0, 0, 0, 0);

  z-index: z-index(modal);
  background-color: $white;
}

.loading__indicator {
  @include abs(50%, null, null, 50%);

  display: none;
  position: absolute;
  width: 170px;
  text-align: center;
  transform: translate(-50%, -50%);

  &.is--active {
    z-index: 40;
    display: inline-block;
    animation: fade-in .1s .3s ease-in-out both;
  }

  &__item {
    display: inline-block;
    // background-color: $black;
    color: $black;
    // clip-path: polygon(0 3%, 100% 0%, 100% 97%, 0% 100%);
    font-weight: 700;
    font-family: $headings-font-family;
    font-size: rem(32);
    text-transform: uppercase;

    .is--active & {
      // animation: sk-bouncedelay 1.4s infinite ease-in-out both;

      &.is--bounce1 {
        //-webkit-animation-delay: -0.32s;
        animation-delay: -.96s;
      }

      &.is--bounce2 {
        //-webkit-animation-delay: -0.16s;
        animation-delay: -.8s;
      }

      &.is--bounce3 {
        //-webkit-animation-delay: -0.16s;
        animation-delay: -.64s;
      }

      &.is--bounce4 {
        //-webkit-animation-delay: -0.16s;
        animation-delay: -.48s;
      }

      &.is--bounce5 {
        //-webkit-animation-delay: -0.16s;
        animation-delay: -.32s;
      }

      &.is--bounce6 {
        //-webkit-animation-delay: -0.16s;
        animation-delay: -.16s;
      }
    }
  }
}

// .indicator-title {
//   position: absolute;
//   bottom: -25px;
//   left: 11px;
//   display: block;
//   text-align: center;
//   color: color(white);
// }

@keyframes sk-bouncedelay {
  /* stylelint-disable selector-list-comma-newline-after */
  0%, 80%, 100% {
    /* stylelint-enable selector-list-comma-newline-after */
    transform: scale(1);
  }

  40% {
    transform: scale(.7);
  }
}
