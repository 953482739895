@each $color,
  $value in $grays {
  @include bg-variant('.bg-gray-#{$color}', $value, true);

  .color-gray-#{$color} {
    color: $value;
    fill: $value;
  }
}

.color-white {
  color: $white;
  fill: $white;
}

.color-black {
  color: $black;
  fill: $black;
}

.bg-black {
  background-color: $black;
  color: $white;
  fill: $white;
}

// used for projects page
.color--project-gray {
  color: $white;
  fill: $white;
  opacity: .3;

  @media (hover: hover) {
    &:hover {
      opacity: 1;
      text-decoration: none;
    }
  }
}

.light {
  fill: $white;
  color: $white;
}

.dark {
  fill: $black;
  color: $black;
}
