// color theme for topbar
.topbar--light {
  .topbar__bar--fixed {
    background-color: rgba(theme-color(dark), 0);
  }

  .topbar__logo__svg {
    fill: $white;
  }

  .topbar__logo__subtitle {
    // color: $white;
    opacity: .3;

    &:hover {
      color: $white;
    }
  }

  .hamburger-toggle {
    &__lines {
      &,
      &::before,
      &::after {
        background: $white;
      }
    }
  }
}

.topbar--semi-light {
  // .topbar__bar:not(.topbar__bar--fixed) {
  //   .topbar__logo__svg {
  //     fill: $white;
  //   }
  //   .topbar__logo__subtitle {
  //     color: $white;
  //   }
  //   .hamburger-toggle {
  //     &__lines {
  //       &,
  //       &::before,
  //       &::after {
  //         background: $white;
  //       }
  //     }
  //   }
  // }
}
