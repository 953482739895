.project-gallery {
  &__title {
    display: none;
  }

  &__list {
    display: grid;
    margin-bottom: -#{map-get($spacers, 1)};
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: map-get($spacers, 2);
    grid-auto-flow: dense;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: map-get($spacers, 4);
    }

    @include media-breakpoint-up(lg) {
      grid-column-gap: map-get($spacers, 5);
    }

    &__item {
      margin-bottom: map-get($spacers, 2);

      @include media-breakpoint-up(sm) {
        margin-bottom: map-get($spacers, 4);

        &:nth-child(even) {
          grid-row: span 2;
        }
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: map-get($spacers, 5);
      }
    }
  }

  &__image-crop {
    @extend .aspect-ratio;

    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &::before {
      padding-top: 75%;
    }

    img {
      @extend .aspect-ratio-element;
    }
  }

  &__footer {
    @extend .spacer-top-s;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
