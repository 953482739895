.text-decoration-none {
  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }

  &:focus {
    text-decoration: none;
  }
}

.text-pre-line {
  white-space: pre-line;
}
