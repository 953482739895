.home-intro-block {
  @include media-breakpoint-up(sm) {
    padding-left: 0;
    padding-right: 0;
  }

  @include media-breakpoint-between(md, md) {
    max-width: 480px;
  }

  @include media-breakpoint-between(lg, lg) {
    max-width: 650px;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 1228px;
  }

  // @media (min-width: 900px) and (max-width: 1024px) {
  //   padding-left: 0;
  //   padding-right: 0;
  //   max-width: 650px;
  // }

  &__summary {
    margin-bottom: map-get($spacers, 2);
  }

  &__text {
    opacity: 0;
    max-height: 0;
    margin-bottom: map-get($spacers, 2);
    overflow: hidden;
    transition: opacity .1s ease-in-out, max-height .4s cubic-bezier(0, 1, 0, 1);

    &--open {
      opacity: 1;
      max-height: 2000px;
      transition: opacity .1s ease-in-out, max-height .3s ease-in;
    }

    ul,
    dl,
    ol,
    p {
      margin-bottom: 2ex;
    }
  }

  &__toggle {
    // margin: map-get($spacers, 2) 0;
    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
