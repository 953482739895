// Functions
@import 'functions/colors';
@import 'functions/get-value-if-key-exists';
@import 'functions/img-path';
@import 'functions/rem';
@import 'functions/strip-units';
@import 'functions/z-index';
@import 'functions/point-to-pixels';
@import 'functions/all-inputs';

// Mixins
@import 'mixins/get-font-size';
@import 'mixins/position';
@import 'mixins/aspect-ratio';
@import 'mixins/horizontal-scroll';
@import 'mixins/get-line-height';

// Config
@import 'config';

// Own reset (instead of reboot/normalize from bootstrap)
@import 'base/reset';

// Bootstrap
@import 'bootstrap/base';
@import 'bootstrap/borders';

// Vendor
@import 'splitting/dist/splitting.css';
@import 'splitting/dist/splitting-cells.css';

// Utils
@import 'utilities/animations';
@import 'utilities/section';
@import 'utilities/container';
@import 'utilities/text-extended';
@import 'utilities/block-link';
@import 'utilities/aspect-ratio';
@import 'utilities/image-caption';
@import 'utilities/object-fit-anchor';

// Base
@import 'base/base';
@import 'base/fonts';
@import 'base/form';
@import 'base/list';
@import 'base/type';
@import 'base/colors';

// Components
@import 'components/button/button';
@import 'components/button/button-group';
@import 'components/hamburger-toggle';
@import 'components/icon/icon';
@import 'components/splitting';
@import 'components/markdown-content';
@import 'components/fixed-footer';
@import 'components/cards';
@import 'components/glide';
@import 'components/block-content';
@import 'components/forms/all';
@import 'components/fstr-cookie';

// Angular components
@import 'src/app/app.module.scss';
