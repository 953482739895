.jobs-page {
  min-height: 80vh;
}

.jobs-page__content {
  @include media-breakpoint-between(md, md) {
    max-width: 500px;
    margin: 0 auto map-get($spacers, 5) auto;
  }
}

.jobs-page__jobs {
  @include media-breakpoint-between(md, md) {
    max-width: 500px;
    margin: 0 auto;
  }
}
