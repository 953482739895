.footer {
  @extend .type-body-small;

  margin-bottom: $spacer;

  @include media-breakpoint-down(sm) {
    padding-bottom: map-get($spacers, 2);
  }

  &.theme--dark {
    background-color: $black;
    color: $white;
    fill: $white;
  }

  .container-xs {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  ul,
  li {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(sm) {
    ul {
      width: 100%;
      justify-content: center;

      li:first-child {
        margin-left: 0;
      }
    }
  }

  a {
    color: inherit;
    // text-decoration: none;
    &:hover {
      text-decoration: none;
      color: gray-color(250);
    }
  }

  &__socials {
    display: flex;
    margin-left: 1ex;

    @include media-breakpoint-down(sm) {
      margin-top: $spacer;
    }

    &__item {
      margin-left: 1ex;
    }
  }

  &__menu-list__item {
    button,
    a {
      margin: 0 1ex;
      color: theme-color(secondary);

      .light &,
      .theme--dark & {
        opacity: .3;
      }

      &:hover {
        text-decoration: none;
        color: #000;

        .theme--dark & {
          opacity: .4;
          color: #fff;
        }

        .light & {
          color: #fff;
        }
      }
    }
  }
}
