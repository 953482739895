.office-contact-card {
  position: relative;

  &__text {
    white-space: break-spaces;
    max-width: 288px;
  }

  &__title {
    font-family: $font-family-base;
    font-weight: normal;
    @include get-font-size(h3-m);
  }

  &__list,
  &__list__item {
    flex-direction: column;
  }

  .phone-link {
    text-decoration: none;
  }
}
