// @import 'scss/press-overview';
.press-overview {
  overflow: hidden;
}

.press-overview__list__detail .close--button {
  @include abs(map-get($spacers, 2), 0, null, null);

  .icon {
    @include size(20px);
  }
}

.press-overview__list__item {
  display: inline-block;
  width: 100%;
  padding: 0 $spacer;

  @include media-breakpoint-up(md) {
    width: 50%;
  }

  @include media-breakpoint-up(lg) {
    width: 33.3333%;
  }

  @include media-breakpoint-up(xl) {
    width: 25%;
  }
}

.press-overview__list {
  list-style: none;
  width: calc(100% + (2 * #{$spacer}));
  position: relative;
  margin-left: -6px;
}

.press-overview__list__detail {
  position: relative;
  width: 100%;
  float: left;
  overflow: hidden;
}
