
.project-page {
  // padding-bottom: map-get($spacers, 6);
  // margin-bottom: -#{map-get($spacers, 5)};

  .container-xxl {
    @include media-breakpoint-between(xl, xl) {
      padding-left: 7vw;
      padding-right: 7vw;
    }
  }

  &__content {
    @include media-breakpoint-down(sm) {
      padding-top: map-get($spacers, 6);
    }

    @include media-breakpoint-between(md, lg) {
      padding-top: map-get($spacers, 6);
    }

    @include media-breakpoint-up(xl) {
      padding-top: map-get($spacers, 6);
    }
  }

  &__specs {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(md) {
      margin-top: map-get($spacers, 6);
    }

    @include media-breakpoint-up(lg) {
      margin-right: 0;
      margin-left: auto;
    }

    @include media-breakpoint-between(md, md) {
      max-width: 490px;
    }

    @include media-breakpoint-between(lg, lg) {
      max-width: 300px;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 610px;
    }
  }

  &__blocks,
  &__gallery,
  &__projects,
  &__news,
  &__content {
    @include media-breakpoint-down(xl) {
      margin-bottom: map-get($spacers, 6);
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: map-get($spacers, 8);
    }
  }

  &__gallery,
  &__projects,
  &__news {
    @include media-breakpoint-between(md, md) {
      padding-left: map-get($spacers, 5);
      padding-right: map-get($spacers, 5);
    }
  }

  &__news .related-news__list__item {
    @include media-breakpoint-between(md, md) {
      margin-bottom: map-get($spacers, 4);
      display: none;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        display: block;
      }
    }
  }

  &__blocks__list__item:last-child {
    .project-content-block {
      padding-bottom: 0;
    }
  }
}
