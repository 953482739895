.project-workfields {
  overflow: hidden;
  position: relative;

  &__title {
    cursor: pointer;
    position: relative;
    // opacity: .3;
    // hyphens: auto;

    @include media-breakpoint-down(sm) {
      // @media only screen and (max-width: 321px) {
      font-size: 25px;
      line-height: 23px;
      // hyphens: manual;
    }

    // @media only screen and (min-width: 322px) and (max-width: 420px) {
    //   font-size: 29px;
    //   line-height: 27px;
    //   hyphens: manual;
    // }

    fstr-icon {
      @include size(16px);
      fill: currentColor;
      display: none;
      
      line-height: 0;

      @include media-breakpoint-down(md) {
        // @media (max-width: 960px) {
        @include size(16px);
        @include abs(null, 0, 4px, null);
        display: inline-block;

      }

      @include media-breakpoint-between(md, md) {
        //@media (min-width: 768px) and (max-width: 960px) {
        // .icon {
          @include size(20px);
        //}
      }
    }

    @media (hover: hover) {
      &:hover {
        // color: gray-color(500);
        opacity: .3;
      }
    }

    @include media-breakpoint-down(md) {
      padding-right: 44px;
    }

    .filter--workfields & {
      opacity: .3;
    }

    &.set--active {
      color: currentColor;
      opacity: 1;
      pointer-events: all;
    }
  }

  &__main {
    max-height: 0;
    overflow: hidden;
    transition: max-height .6s cubic-bezier(0, 1, 0, 1);

    &.set--open {
      max-height: 1000px;
      transition: max-height .6s ease-in;
    }
  }

  &__content {
    @include media-breakpoint-up(sm) {
      max-width: 60%;
    }

    @include media-breakpoint-down(md) {
      padding: map-get($spacers, 3) 0;

      p {
        padding-bottom: map-get($spacers, 3);
      }
    }
  }

  .button  {
    fstr-icon  {
      @include size(16px);
      line-height: 0;
    }
    .icon {
      transform: translate(1ex, -1px);
    }
  }

  @include media-breakpoint-down(md) {
    margin-bottom: map-get($spacers, 3);
  }
}
