.input-field {
  border-bottom: 1px solid;
  margin-bottom: map-get($spacers, 3);
  position: relative;

  input {
    height: 44px;
    width: 100%;

    &::placeholder {
      color: rgba(#fff, 0);
    }

    &:not(:placeholder-shown) + label,
    &:focus + label {
      transform: scale(.7) translateY(-38px);
    }

    &.error + label {
      color: theme-color(danger);
    }

    &.error ~ .error-message {
      @include abs(100%, 0, null, null);

      font-size: .8em;
      color: theme-color(danger);
    }
  }
}

.input-field__label {
  @include abs(50%, null, null, 0);

  transform: translateY(-50%);
  transform-origin: left top;
  transition: transform .2s ease-in-out;
  pointer-events: none;
}
