.topbar {
  @include fxd(0, 0, auto, 0);

  pointer-events: none;
  z-index: z-index(nav);
  width: 100%;

  .open--modal & {
    z-index: 1;
  }

  &__bar {
    // @extend .spacer-top-m;
    // pointer-events: none;
    background: $white;
    // transition: height .2s, padding .2s;
    height: 60px;
    display: flex;
    width: 100%;
    z-index: z-index(nav) +10;

    .set--no-bg & {
      background-color: transparent;
    }

    &.set--small {
      height: auto;
    }

    .topbar--row & {
      height: 90px;
      // background-color: $white;

      @include media-breakpoint-up(xl) {
        height: 96px;
      }
    }

    @include media-breakpoint-down(sm) {
      padding-top: map-get($spacers, 2);
      background-color: $white;
      height: 53px;
    }

    @include media-breakpoint-between(md,lg) {
      padding-top: map-get($spacers, 3);
    }

    @include media-breakpoint-up(md) {
      height: 88px;

      &.set--small {
        height: 58px;
      }
    }

    @include media-breakpoint-up(xl) {
      height: 96px;
      padding-top: map-get($spacers, 3);

      &.set--small {
        height: 58px;
      }
    }

    &--fixed {
      // position: fixed;
      // width: 100%;
      // height: 70px;
      // padding-top: map-get($spacers, 2);
      // padding-bottom: map-get($spacers, 2);
      // background-color: $white;
      background-color: transparent;
      // animation: slide-in-top .3s ease-in-out both;
      z-index: z-index(nav) +10;

      // @include media-breakpoint-down(sm) {
      //   height: 53px;
      // }

      // @include media-breakpoint-between(md, lg) {
      //   height: 60px;
      // }
    }

    .topbar--light & {
      background-color: $black;
    }

    .main-nav--active & {
      background: transparent;
    }

    &__inner {
      display: flex;
      justify-content: space-between;
      // margin: auto;
    }
  }

  .hamburger-toggle {
    position: relative;
    z-index: z-index(nav) + 1;
    pointer-events: all;

    &__lines--active {
      &,
      &::before,
      &::after {
        background: $white;
      }
    }
  }
}

.topbar__logo-wrapper {
  display: flex;
  flex-wrap: wrap;
  // max-width: 730px;
  // align-items: center;

  &.set--row {
    // flex-direction: column;
    // align-items: flex-start;
    // flex-wrap: nowrap;

    // .topbar__bar--fixed & {
    //   flex-direction: row;
    //   align-items: center;
    //   flex-wrap: wrap;
    //   max-width: 90vw;
    // }
  }

  .main-nav--active & {
    z-index: 100;
    fill: $white;

    .topbar__logo {
      opacity: 0;
      pointer-events: none;
    }

    .type--svg {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.topbar__logo {
  pointer-events: all;

  @include media-breakpoint-between(md, lg) {
    font-size: 32px;
    line-height: 24px;
  }

  @include media-breakpoint-up(xl) {
    line-height: 32px;
  }

  .set--row & {
    @include media-breakpoint-down(sm) {
      max-width: 50%;
    }

    @include media-breakpoint-up(sm) {
      .topbar__logo__subtitle {
        //  margin-top: $spacer;
      }
    }
  }

  &.type--svg {
    // margin-right: .5ex;
    // margin-left: 3px;
  }

  &.type--page {
    color: gray-color(250);
    // transform: translateY(-4px);
    // white-space: pre;
    max-width: 74vw;
    max-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1;
    line-clamp: 1;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    transition: opacity .2s .2s ease-in;

    @include media-breakpoint-down(sm) {
      max-height: 50px;
      max-width: 80vw;
      line-clamp: 2;
      -webkit-line-clamp: 2; /* number of lines to show */
      margin-top: 3px;

      .set--row & {
        transform: translateY(0);
      }

      .topbar__bar--fixed & {
        transition: opacity .01s linear;
        opacity: 0;
        pointer-events: none;
      }
    }

    @include media-breakpoint-between(md, lg) {
      max-height: 32px;
    }
  }

  &__link {
    margin-right: map-get($spacers, 2);
    // display: inline-flex;
    // align-items: center;
    text-decoration: none;
    color: inherit;
    display: inline;

    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__svg {
    display: inline-block;
    height: .75em;
    fill: inherit;
  }
}
