.project-details {
  overflow: hidden;

  .button-group {
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;

      .project-details__project-button {
        width: 100%;
        margin-bottom: $spacer*3;
      }

      .button {
        margin-bottom: $spacer;
      }
    }
  }

  .reset-selected {
    display: none;
  }

  .set--selected-item & .reset-selected {
    display: block;
  }
}

.project-details__list__item {
  position: relative;
  margin-bottom: map-get($spacers, 2);
}
