app-filter-bar { display: contents; }

.filter-bar {
  @include media-breakpoint-up(xxl) {
    padding-top: 36px;
  }

  &__search {
    width: 100%;
    font-family: $font-family-sans-serif;

    @include media-breakpoint-between(xl, xl) {
      @include abs(24px, null, null, 12px);
    }

    &::placeholder {
      color: $black;
    }

    .light & {
      &::placeholder {
        @extend .color--project-gray;
      }
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: none;

    &:checked + span {
      color: inherit;
      opacity: 1;
    }
  }

  label {
    width: 100%;
  }

  label span {
    .light & {
      @extend .color--project-gray;
    }
  }

  label:hover {
    span { color: inherit; }
  }

  .button-link {
    .light & {
      @extend .color--project-gray;
    }

    margin-left: 0;

    &.set--active {
      opacity: 1;
    }
  }

  &__search {
    @include media-breakpoint-up(xxl) {
      margin-bottom: map-get($spacers, 3);
    }

    @include media-breakpoint-down(xl) {
      // margin-bottom: map-get($spacers, 1);
    }
  }

  &__filters {
    .button {
      font-size: inherit;
    }

    @include media-breakpoint-between(md, xl) {
      display: flex;
    }

    @include media-breakpoint-between(md, lg) {
      flex-wrap: wrap;
      flex: 0 0 calc(100% + #{map-get($spacers, 2)});
      max-width: 103%;
      margin-bottom: -#{ map-get($spacers, 3)};
    }

    @include media-breakpoint-down(lg) {
      .button-group {
        width: 100%;
        margin-bottom: map-get($spacers, 3);
      }
    }

    @include media-breakpoint-between(xl,xl) {
      width: 90%;
      max-width: 90%;
      flex: 1;
    }

    &__toggle.set--active {
      color: inherit;
    }

    .button-group {
      flex-direction: column;

      @include media-breakpoint-between(xl, xl) {
        width: 16%;
      }

      // .button-link {
      //   margin-bottom: map-get($spacers, 1);
      // }
    }

    @include media-breakpoint-up(xxl) {
      display: flex;
      flex-direction: column;

      .button-group {
        order: 300;
        margin-top: map-get($spacers, 3);
      }

      .filter-bar__filters__toggle {
        display: none;
      }
    }
  }

  &__filters__list {
    margin-bottom: 0;

    @include media-breakpoint-between(md, lg) {
      // width: 50%;
      flex: 0 0 calc(50% - #{map-get($spacers, 2)});
      margin-right: #{map-get($spacers, 2)};

      .set--open & {
        margin-bottom: map-get($spacers, 3);
      }
    }

    @include media-breakpoint-down(xl) {
      max-height: 0;
      // flex: 1;
      overflow: hidden;
      opacity: 0;
      margin-bottom: 1ex;
      // transition: opacity .1s ease-in-out, max-height .6s cubic-bezier(0, 1, 0, 1);

      .filter--active & {
        max-height: 200px;
        opacity: 1;

        dd {
          display: none;

          &.set--active {
            display: block;
          }
        }
      }

      .set--open & {
        opacity: 1;
        max-height: 1000px;
        transition: opacity .1s ease-in-out, max-height .3s ease-in;
      }

      .set--open.filter--active & {
        dd {
          display: block;
        }
      }
    }

    @include media-breakpoint-down(xl) {
      transition: opacity .1s .3s ease-in-out, max-height .3s ease-in;

      .filter--active & {
        transition: opacity .1s ease-in-out, max-height .3s cubic-bezier(0, 1, 0, 1);
      }

      .set--open & {
        transition: opacity .1s ease-in-out, max-height .3s ease-in;
      }
    }

    @include media-breakpoint-between(xl, xl) {
      padding: 0 map-get($spacers, 1);
      flex: 1;
      // transition: opacity .1s .6s ease-in-out, max-height .6s ease-in;

      // .filter--active & {
      //   transition: opacity .1s ease-in-out, max-height .6s cubic-bezier(0, 1, 0, 1);
      // }

      // .set--open & {
      //   transition: opacity .1s ease-in-out, max-height .6s ease-in;
      // }
    }

    @include media-breakpoint-up(xxl) {
      margin-top: map-get($spacers, 2);
      margin-bottom: map-get($spacers, 2);
      order: 2;
    }

    &__title {
      margin-bottom: map-get($spacers, 1);
      border-bottom: 1px solid;
      padding-bottom: map-get($spacers, 1);

      @include media-breakpoint-down(xl) {
        cursor: pointer;
      }
    }

    dd {
      position: relative;
      margin-bottom: 2px;
      color: theme-color(secondary);

      &.set--active,
      &:hover {
        color: $black;

        .light & {
          color: $white;
        }
      }
    }
  }

  &__options {
    display: flex;
    justify-content: flex-end;
  }
}
