:root {
  --publication-card-width: 280px;
  --publication-card-height: 280px;
  --publication-card-image-padding: 36px 36px;
}

.publication-card {
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-flow: column;
  }

  &__figure {
    background-color: gray-color(100);
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--publication-card-width);
    height: var(--publication-card-height);
    min-width: var(--publication-card-width);
    padding: var(--publication-card-image-padding);
    margin: 0;
    margin-inline-end: map-get($spacers, 2);

    img {
      height: 100%;
      width: auto;
      object-fit: contain;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: auto;
      margin-bottom: 12px;
      margin-inline-end: 0;

      img {
        max-height: 220px;
      }
    }
  }
}
