.button-group {
  display: flex;

  .button {
    margin-left: 1ex;

    &:first-of-type {
      margin-left: 0;
    }
  }
}
