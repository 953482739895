.project-card-small {
  @include size(100%);

  position: relative;

  &__crop {
    // @extend .aspect-ratio;
    @include size(100%);

    position: relative;
    background-color: $black;
    margin: 0;

    &::before {
      display: none;
      padding-top: 75%;
      background-color: $black;

      @include media-breakpoint-between(md, md) {
        padding-top: 92%;
      }
    }

    img {
      // @extend .aspect-ratio-element;
      width: 100%;
      object-fit: cover;
      opacity: .8;
      transition: opacity .1s ease-in-out;
      animation: fade-in .1s ease-in-out;
    }
  }

  &__title {
    @include abs(null, map-get($spacers, 3), map-get($spacers, 2), map-get($spacers, 3));

    font-family: $headings-font-family;
    padding: map-get($spacers, 1) map-get($spacers, 2);
    border: 2px solid;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    background-color: rgba($black, .2);
    word-wrap: break-word;

    @include media-breakpoint-down(sm) {
      left: map-get($spacers, 2);
      right: map-get($spacers, 2);
      bottom: map-get($spacers, 1);
      padding: map-get($spacers, 1) map-get($spacers, 1);
    }
  }

  @media (hover: hover) {
    .project-card-small__title {
      transition: background-color .2s ease-in-out;
    }

    &:hover {
      .project-card-small__title {
        background-color: $white;
        color: $black;
        border-color: $white;
      }
    }
  }
}
