.home {
  &__header {
    .header__content {
      // animation: fade-out 0.2s 3s ease-in-out both;
    }
  }

  .slider-block {
    @extend .spacer-bottom-l;

    @include media-breakpoint-down(sm) {
      padding-bottom: map-get($spacers, 6);
    }

    @include media-breakpoint-between(md, lg) {
      padding-bottom: map-get($spacers, 5);
    }
  }

  &-news__list__item {
    @include media-breakpoint-down(sm) {
      display: none;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        display: block;
      }
    }
  }
}
