// class added to body
.page--style-guide {}

// component
.style-guide {
  .guide-color {
    width: 100%;

    figure { display: flex; }

    &__tone { @include size(120px)}

    &__caption {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-flow: column;
      padding: 8px;
      color: gray-color(500);
    }
  }

  .grid-showcase {
    outline: 1px solid color(blue);

    &__inner {
      outline: 1px solid color(pink);
      height: 75vh;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: rem(12px);
    }

    &__spacer {
      position: absolute;
      top: 0;
    }
  }
}