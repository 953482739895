@import 'button-reset';

$button-size: 35px;
$button-padding-x: 30px;
$font-baseline-offset: 5px;

.button {
  @extend %button-reset;
  // @extend .type-body-medium;

  display: inline-flex;
  position: relative;
  // color: inherit;
  // fill: inherit;
  height: rem($button-size);
  align-items: center;
  padding-right: rem($button-padding-x);
  padding-left: rem($button-padding-x);
  overflow: hidden;
  font-family: $headings-font-family;
  line-height: rem($button-size + $font-baseline-offset);
  text-decoration: none;
  text-transform: uppercase;
  // transition: all .1s ease-in-out;

  fstr-icon {
    @include size(16px);
    line-height: 0;
  }

  @include media-breakpoint-up(md) {
    height: rem($button-size * 1.15);
    padding-right: rem($button-padding-x * 1.2);
    padding-left: rem($button-padding-x * 1.2);
  }

  @include media-breakpoint-up(xl) {
    height: rem($button-size * 1.4);

    @include get-font-size(medium-text-l);
    @include get-line-height(medium-text-l);
  }

  &:disabled {
    pointer-events: none;
    opacity: .3;
  }

  // &::after {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   text-align: center;
  //   content: attr(data-text);
  //   opacity: .5;
  //   transition: all .2s;
  //   transform: translate(0, rem($button-size));
  // }

  .icon {
    fill: inherit;
  }

 

  span {
    // transition: all .2s;
    transform: translate(0, 0);
    display: block;
    width: 100%;
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: none;

      // span {
      //   opacity: .5;
      //   transform: translate(0, rem(-$button-size));
      // }

      // &::after {
      //   opacity: 1;
      //   transform: translate(0, 0);
      // }
    }
  }

  &--border {
    color: inherit;
    border: 2px solid;
    text-align: center;
    width: 100%;
    max-width: 450px;

    @include media-breakpoint-up(xl) {
      max-width: 500px;
    }

    &.color-white {
      @media (hover: hover) {
        &:hover {
          background-color: $white;
          color: $black;
          fill: $black;
          border-color: $white;
        }
      }
    }

    &.color-black {
      @media (hover: hover) {
        &:hover {
          background-color: $black;
          color: $white;
          fill: $white;
          border-color: $black;
        }
      }
    }
  }

  &--small {
    padding-right: rem($button-padding-x*0.5);
    padding-left: rem($button-padding-x*0.5);
    min-width: 200px;

    @include media-breakpoint-up(md) {
      padding-right: rem($button-padding-x);
      padding-left: rem($button-padding-x);
      height: rem($button-size);
      line-height: 2.1rem;
      min-width: 290px;
      width: auto;
    }

    @include media-breakpoint-up(sm) {
      font-size: 16px;
    }
  }

  &-link {
    -webkit-appearance: none;
    padding: 0;
    line-height: inherit;
    height: auto;
    background: transparent;
    border: none;
    outline: none;
    text-decoration: none;
    // transition: color .2s;
    text-transform: none;
    font-family: inherit;

    &:focus {
      outline: none;
    }

    @media (hover: hover) {
      &:hover {
        color: gray-color(700);
        text-decoration: underline;
      }
    }
  }
}

@each $name, $colors in $button-base-styles {
  $font-color: nth($colors, 1);
  $bg-color: nth($colors, 2);

  .button--#{$name} {
    color: $font-color;
    background-color: $bg-color;

    .icon * {
      fill: $font-color;
    }

    @media (hover: hover) {
      &:hover {
        color: $font-color;
        background-color: lighten($bg-color, 40%);
      }
    }

    &:focus {
      color: $font-color;
      background-color: lighten($bg-color, 40%);
    }
  }
}
