.slider {
  @media (hover: hover) {
    &.set--slider.set--cursor-arrow {
      cursor: none;
    }
  }

  &__item {
    position: relative;
  }

  &__page {
    display: none;
  }

  &.set--cursor-arrow .glide__arrow {
    @media (hover: hover) {
      opacity: 0;
    }
  }

  &__cursor {
    position: fixed;
    pointer-events: none;
    z-index: 9000;
    display: none;

    @media (hover: hover) {
      .set--cursor-arrow & {
        display: block;
      }
    }

    &__pointer {
      @include size(44px);

      transform: translate(-50%, -50%);
      background: url('/assets/gfx/icon-chevron-left-white.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      // transition: transform .2s ease-in-out;
      // background: red;

      &.type--right {
        transform: translate(-50%, -50%) scaleX(-1);
      }
    }
  }
}
