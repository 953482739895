.input-checkfield {
  position: relative;

  @include size(20px);

  input[type='radio'],
  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
  }

  fstr-icon {
    display: contents;
  }

  input[type='checkbox'] ~ label {
    @include size(20px);

    // position: relative;
    background-color: #fff;

    .icon {
      @include abs(50%, null, null, 50%);
      @include size(12px);

      fill: black;
      transform: translate(-50%, -50%) scale(0);
    }
  }

  input[type='checkbox']:checked ~ label .icon {
    transform: translate(-50%, -50%) scale(1);
  }
}

.input-checkfield__labels {
  position: relative;
}
