@each $color, $value in $theme-colors {
  .icon-#{"" + $color} {
    fill: $value;
  }
}

.icon-white { fill: $white !important; }
.icon-body { fill: $body-color !important; }
.icon-muted { fill: $text-muted !important; }

.icon-black-50 { fill: rgba($black, .5) !important; }
.icon-white-50 { fill: rgba($white, .5) !important; }
