::selection {
  background-color: gray-color(100);
}

:root {
  --projectsMinHeight: 0;
}


body {
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}

body {
  @include get-font-size(small-text-s);
  @include get-line-height(small-text-s);

  @include media-breakpoint-up(sm) {
    @include get-font-size(small-text-m);
    @include get-line-height(small-text-m);
  }

  @include media-breakpoint-up(lg) {
    @include get-font-size(small-text-l);
    @include get-line-height(small-text-l);
  }
}

a {
  display: inline-block;
  // transition: $transition-base;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

svg {
  display: block;
}

pre {
  white-space: pre-wrap;
}

hr,
.hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $border-width solid $border-color;

  &--lg {
    border-top: $border-width-lg solid $border-color;
  }

  &--light {
    border-color: $border-color-light;
  }
}

dt,
strong {
  font-weight: inherit;
}

p,
.p {
  // @include get-font-size(body)
  // line-height: 2;
}
