/*
* usage with Angular Directive splitting in core
* <span [splitting] class="splitting">hallo dit is een zin</span>
* see: https://splitting.js.org/guide.html for more info
*/

%splitting {
  .word {
    display: inline-block;
    overflow: hidden;
  }

  .char {
    opacity: 0;
    transition: transform .2s, opacity .2s;
    transform: translate(0, 1em);
  }
}

%splitting-active {
  opacity: 1;
  transform: translate(0, 0);
  transition-delay: calc(4ms*var(--char-index));

  @include media-breakpoint-up(md) {
    transition-delay: calc(8ms*var(--char-index));
  }
}

.splitting {
  @extend %splitting;

  &--active {
    @extend %splitting-active;
  }
}
