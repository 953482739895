.award-year {
  display: flex;
  gap: 24px;
  max-width: 500px;

  &__label {
    min-width: 72px;
  }
}

.awards-overview {
  @include media-breakpoint-down(sm) {
    margin-top: map-get($spacers, 4);
  }
}

.awards-list__item {
  margin-bottom: 36px;
}

.publication-list__item {
  margin-bottom: 12px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 24px;
  }
}
