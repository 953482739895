.app {
  position: relative;
  min-height: 100vh;
  padding-top: 60px;

  @include media-breakpoint-up(md) {
    padding-top: 88px;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 96px;
  }
  // padding-top: 100px;

  @include media-breakpoint-down(sm) {
    // padding-top: 80px;
  }

  &.light {
    @extend .bg-dark;
    @extend .text-white;
  }

  &__wrapper {
    position: relative;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    z-index: 3;
    min-height: calc(100vh - 155px);

    &.anim--in {
      opacity: 1;
    }
  }
}
