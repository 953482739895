.project-specs {
  // padding: $spacer 0;
  display: block;
  overflow: hidden;
  position: relative;

  ul,
  p,
  ol,
  dd,
  li {
    margin-bottom: 0;
  }

  &__title {
    font-weight: 400;
    margin-bottom: 2px;
    font-size: 14px;

    // @include media-breakpoint-down(sm) {
    //   font-size: 14px;
    // }
  }

  // 
  &__content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity .1s ease-in-out, max-height .6s cubic-bezier(0, 1, 0, 1);

    // @include media-breakpoint-down(sm) {
    //   @include get-font-size(medium-text-m);
    //   @include get-line-height(medium-text-m);
    // }

    // @include media-breakpoint-up(md) {
    //   @include get-font-size(medium-text-l);
    //   @include get-line-height(medium-text-l);
    // }

    a {
      position: relative;
      z-index: 10;
    }

    &--open {
      opacity: 1;
      max-height: 1000px;
      transition: opacity .1s ease-in-out, max-height .6s ease-in;
      margin-bottom: 5px !important;
    }
  }

  .button-link {
    // @include media-breakpoint-up(xxl) {
    //   @include get-font-size(medium-text-l);
    // }
    //   @include abs(0, 0, null, null);

    //   z-index: 7;
    //   height: 40px;
    //   width: 100%;
    //   font-size: 0;
    //   color: transparent;

    //   fstr-icon {
    //     @include abs(9px, 0, null, null);
    //   }

    //   @include media-breakpoint-down(lg) {
    //     .icon {
    //       @include size(12px);

    //       min-width: 4px;
    //       min-height: 4px;
    //     }
    //   }
  }

  &.type--program {
    .project-specs__options {
      display: none;
    }

    .col-14 {
      flex: 0 0 66.66%;
      max-width: 66.66%;
    }
  }

  &.type--awards .project-specs__content li {
    margin-top: 1ex;

    &:first-child {
      margin-top: 0;
    }
  }

  &.type--location {
    ul li {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
