.related-news {
  &__title {
    line-height: .7;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: map-get($spacers, 2);
    grid-auto-flow: dense;
    margin-bottom: 0;

    @include media-breakpoint-between(md, md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &__item {
      margin-bottom: map-get($spacers, 2);

      @include media-breakpoint-between(md, md) {
        margin-bottom: map-get($spacers, 4);

        //   grid-column: auto / span 2;

        //   &:nth-child(1) {
        //     grid-column: 1 / span 3;
        //     grid-row: 1 / span 2;
        //   }
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: map-get($spacers, 3);

        &:nth-child(1) {
          grid-column: 1 / span 2;
          grid-row: 1 / span 2;
        }
      }
    }
  }

  &__footer {
    @extend .spacer-top-s;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
