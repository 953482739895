.container {
  position: relative;
  max-width: 100%;

  @include media-breakpoint-up(xl) {
    max-width: map-get($container-max-widths, xl);
  }

  @include media-breakpoint-up(xxl) {
    max-width: map-get($container-max-widths, xxl);
  }

  // KCAP specific
  &,
  &-fluid,
  &-xs,
  &-md,
  &-xl {
    padding-right: map-get($spacers, 2);
    padding-left: map-get($spacers, 2);

    // @include media-breakpoint-up(md) {
    //   padding-right: map-get($spacers, 2);
    //   padding-left: map-get($spacers, 2);
    // }

    @include media-breakpoint-up(lg) {
      padding-right: map-get($spacers, 3);
      padding-left: map-get($spacers, 3);
    }
  }
}

.container-xxl {
  @include media-breakpoint-up(lg) {
    padding-right: map-get($spacers, 7);
    padding-left: map-get($spacers, 7);
  }

  @include media-breakpoint-up(xl) {
    padding-right: map-get($spacers, 8);
    padding-left: map-get($spacers, 8);
  }

  @include media-breakpoint-up(xxl) {
    padding-right: map-get($spacers, 3);
    padding-left: map-get($spacers, 3);
  }
}

.container-fluid {
  max-width: 1900px;
}

@each $name, $value in $container-max-widths {
  .container-#{$name} {
    max-width: rem($value);
  }
}
