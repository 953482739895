.topbar__nav {
  @include fxd(0);

  background: transparent;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  color: $white;
  transition: background .2s, opacity .2s;

  &--active {
    opacity: 1;
    z-index: z-index(nav);
    pointer-events: auto;
    background: rgba(gray-color(250), 1);
  }

  &__footer {
    margin-bottom: $spacer;
    margin-top: auto;
    width: 100%;
    padding-bottom: map-get($spacers, 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__top {
      margin-bottom: $spacer;

      @include media-breakpoint-down(sm) {
        flex-direction: column;

        > ul {
          margin-top: $spacer;
        }
      }
    }

    button,
    a {
      color: inherit;

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
          color: $black;
        }
      }
    }

    // .topbar__nav--active & {
    //   // animation: fade-in .3s 1s ease-in-out both;
    // }

    li,
    ul { margin-bottom: 0; }

    &__list {
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        margin-bottom: .5ex;
      }

      &__item {
        margin-left: 1ex;

        &:first-child {
          margin-left: 1.5ex;
        }

        @include media-breakpoint-down(sm) {
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  &__buttons {
    width: 100%;
    justify-content: center;
    color: $white;
    padding-top: map-get($spacers, 6);
    // .topbar__nav--active & {
    //   animation: fade-in .3s .8s ease-in-out both;
    // }

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      align-items: center;
      padding-left: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);

      .button {
        margin-top: map-get($spacers, 2);
        margin-left: 0;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
}

.nav-list {
  margin-top: auto;
  margin-bottom: 0;

  &__item__link {
    color: $white;
    // transition: color .2s;
    margin: 0;

    @include media-breakpoint-down(sm) {
      font-size: 48px;
      line-height: 43px;
    }

    @include media-breakpoint-between(md, lg) {
      font-size: 80px;
      line-height: 72px;
    }

    @media (hover: hover) {
      &:hover {
        color: $black;
      }
    }

    &__active {
      color: $black;
    }
  }

  // splitting
  &__item {
    // @extend %splitting;

    width: 100%;

    // .topbar__nav--active & .char {
    //   // @extend %splitting-active;

    //   transition-delay: calc(.1s * var(--list-index) + 15ms * var(--char-index));

    //   @include media-breakpoint-up(md) {
    //     transition-delay: calc(.15s * var(--list-index) + 20ms * var(--char-index));
    //   }
    // }
  }
}
