.news-detail {
  &__header {
    text-transform: uppercase;
  }

  .header__crop::before {
    opacity: 0;
  }

  &__header,
  &__slider {
    padding-bottom: map-get($spacers, 3);
  }

  .container-md {
    @include media-breakpoint-between(md, md) {
      max-width: 480px;
      padding: 0;
    }

    @include media-breakpoint-between(lg, lg) {
      // @media (min-width: 960px) and  (max-width: 1025px) {
      max-width: 646px;
    }
  }

  .container-xxl {
    @include media-breakpoint-between(md, md) {
      max-width: 600px;
    }
  }

  &__content {
    // @include media-breakpoint-up(xl) {
    max-width: 720px;
    margin: 0 auto;
    //  }
  }

  &__title,
  &__date {
    @include get-font-size(h2-s);
    @include get-line-height(h2-s);

    display: inline;
    margin-bottom: 0;
    margin-inline-end: .5ex;

    @include media-breakpoint-up(lg) {
      @include get-font-size(h2-m);
      @include get-line-height(h2-m);
    }

    @include media-breakpoint-up(xl) {
      @include get-font-size(h2-l);
      @include get-line-height(h2-l);
    }
  }

  &__date {
    overflow-wrap: break-word;
  }

  &__slider {
    .header {
      padding-bottom: 66.66%;
      // background-color: theme-color(secondary);

      .header__image {
        animation: none;
      }

      @include media-breakpoint-down(sm) {
        padding-bottom: 66.66%;
      }
    }

    .header__crop {
      @include abs(0,0,0,0);

      padding-top: 0;
      // height: auto;
      @include size(100%);
      // position: relative;
      // width: 100%;
    }
  }

  .markdown-content {
    blockquote {
      padding: map-get($spacers, 2) 0;
      font-size: 20px;
      // margin-bottom: 0;

      p {
        display: inline;
      }

      &::after,
      &::before {
        display: none;
      }
    }
  }
  // .markdown-content {
  //   blockquote {
  //     padding: map-get($spacers, 5) 0;

  //     @include get-font-size(h3-s);
  //     @include get-line-height(h3-s);

  //     @include media-breakpoint-up(sm) {
  //       @include get-font-size(h3-m);
  //       @include get-line-height(h3-m);
  //     }
  //   }
  // }

  @include media-breakpoint-down(lg) {
    .glide__arrow {
      background-size: 50% 50%;
    }

    .glide__arrow--right {
      right: 0;
    }

    .glide__arrow--left {
      left: 0;
    }
  }

  &__buttons {
    //  padding-bottom: map-get($spacers, 6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // @include media-breakpoint-up(xl) {
    //   padding-top: map-get($spacers, 8);
    //   padding-bottom: map-get($spacers, 8);
    // }
  }

  &__related-news {
    // padding-top: map-get($spacers, 6);

    // @include media-breakpoint-up(xl) {
    //   padding-top: map-get($spacers, 8);
    // }
  }

  &__related-projects {
    // padding-top: map-get($spacers, 6);

    // @include media-breakpoint-up(xl) {
    //   padding-top: map-get($spacers, 8);
    // }
  }
}

.intro-text {
  //  @include get-font-size(medium-text-l);
  //  @include get-line-height(medium-text-l);

  @include media-breakpoint-down(md) {
    font-size: 1.1em;
  }
}
