.card {
  &__figure {
    @include aspect-ratio(70.25%);

    perspective: rem(100px);

    img {
      @extend .aspect-ratio-element;
    }
  }
}

.card--hover-black {
  .card__figure {
    background-color: $black;
    position: relative;
  }

  .card__figure__read-more {
    @include abs(null, map-get($spacers, 3), map-get($spacers, 3), map-get($spacers, 3));

    @extend .button--small;

    color: white;
    fill: white;
    width: auto;
    max-width: 100%;
    min-width: 10px;
    text-align: center;
    justify-content: center;
    opacity: 0;
    transition: opacity .1s ease-in-out;

    @include media-breakpoint-down(lg) {
      right: map-get($spacers, 2);
      left: map-get($spacers, 2);
      bottom: map-get($spacers, 2);
    }
  }

  @media (hover: hover) {
    .card__figure img {
      transition: opacity .1s ease-in-out;
    }

    &:hover .card__figure {
      .card__figure__read-more {
        opacity: 1;
      }

      img {
        // transform: translate3d(0, 0, rem(2px));
        opacity: .25;
      }
    }
  }
}
