.related-projects {
  &__title {
    line-height: .7;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  @include media-breakpoint-between(md, md) {
    .col-md-8 {
      max-width: 50%;
      flex: 0 0 50%;
    }
  }

  @include media-breakpoint-between(lg,xl) {
    .col-md-8 {
      max-width: 25%;
      flex: 0 0 25%;

      &:nth-child(10),
      &:nth-child(9) {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    .col-xl-5 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}
