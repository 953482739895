.contact-page {
  .home-slider__footer .button--border,
  .button--small {
    @include media-breakpoint-down(sm) {
      margin: 0 map-get($spacers, 3);
      width: calc(100% - #{2*(map-get($spacers, 3))});
    }
  }

  &__block {
    display: block;
    margin-bottom: map-get($spacers, 5);

    &.type--0 {
      margin-bottom: map-get($spacers, 5);
    }
  }

  .people-employees {
    min-height: 0;
  }

  .people-employees__list__item {
    margin-bottom: map-get($spacers, 2);
  }

  .employee-card.no--image {
    .employee-card__crop::before {
      padding-top: 34%;
    }
  }

  &__sidebar {
    // @include media-breakpoint-down(lg) {
    //   margin-bottom: map-get($spacers, 4);
    // }

    @include media-breakpoint-down(md) {
      margin-bottom: map-get($spacers, 2);
    }

    &__text {
      p { margin-bottom: 0; }
    }
  }

  &__content {
    // max-width: 450px;

    // @include media-breakpoint-between(md, md) {
    //   max-width: 690px;
    // }

    // @include media-breakpoint-between(lg, lg) {
    //   max-width: 850px;
    // }

    // @include media-breakpoint-up(xxl) {
    //   max-width: none;
    // }

    // @include media-breakpoint-down(lg) {
    //   margin: 0 auto;
    // }
  }
}

.contact-page__contacts-list {
  flex-wrap: wrap;

  // @include media-breakpoint-between(s, sm) {
  //   padding-left: rem(50);
  //   padding-right: rem(50);
  // }

  &__item {
    flex: 0 0 50%;

    // @include media-breakpoint-between(sm, md) {
    //   flex: 0 0 50%;
    // }

    @include media-breakpoint-up(md) {
      flex: 0 0 33.3333%;
    }
  }
}
