
.people-page {
  &__blocks {
    @include media-breakpoint-down(sm) {
      padding-top: map-get($spacers, 6);
    }

    @include media-breakpoint-between(md, lg) {
      padding-top: map-get($spacers, 6);
    }

    @include media-breakpoint-down(xl) {
      margin-bottom: map-get($spacers, 6);
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: map-get($spacers, 8);
      padding-top: map-get($spacers, 6);
    }
  }

  &__blocks__list__item {
    margin-bottom: map-get($spacers, 4);
  }

  @include media-breakpoint-up(xl) {
    &__blocks__list__item {
      margin-bottom: map-get($spacers, 6);

      .people-content-block {
        // flex-direction: row-reverse;
        &__main {
          grid-column: 2;
        }

        &__main__footer {
          margin-bottom: 0;
          margin-top: auto;
        }

        &__images {
          grid-column: 1;
        }
      }

      &:nth-child(2) {
        margin-top: calc(1px - ((var(--peopleMain) + #{map-get($spacers, 6)} )));
        min-height: var(--peopleMain);

        .people-content-block {
          height: 100%;

          // @include media-breakpoint-up(xl) {
          //   min-height: var(--peopleMain);
          // }

          &__main {
            padding-top: map-get($spacers, 2);
            display: flex;
            flex-direction: column;
            min-height: var(--peopleMain);
          }

          &__images {
            display: none;
          }
        }
      }

      &:nth-child(3) {
        .people-content-block {
          &__main {
            grid-row: 2;
          }

          &__images {
            grid-column: span 2;
            grid-row: 1;
          }

          &__image {
            width: 88%;
            margin-right: 0;
            margin-left: auto;
            overflow: hidden;

            img {
              @include size(100%);

              max-height: 90vh;
              object-fit: cover;
              max-width: none;
            }
          }
        }
      }
    }
  }

  &__blocks__list__item:nth-child(4),
  &__blocks__list__item:nth-child(3) {
    .people-content-block {
      flex-direction: column-reverse;

      &__images {
        margin-bottom: map-get($spacers, 3);
      }
    }
  }

  &__blocks__list__item:nth-child(3) .people-content-block {
    @include media-breakpoint-between(md, lg) {
      &__images {
        grid-template-columns: 70% auto;
      }
    }

    @include media-breakpoint-up(xl) {
      &__images {
        margin-bottom: map-get($spacers, 6);
      }
    }
  }

  &__blocks__list__item:nth-child(4) {
    // @include media-breakpoint-down(lg) {
    .people-content-block {
      &__main {
        display: flex;
        flex-direction: column;

        .people-content-block__quote {
          order: 1;
          margin-top: 0;
          margin-bottom: map-get($spacers, 6);
        }

        .people-content-block__text,
        .people-content-block__title {
          order: 2;
        }

        .people-content-block__text {
          padding-bottom: 0;
        }
      }
    }
    //}

    @include media-breakpoint-up(xl) {
      margin-top: calc(1px - (var(--elContentHeight) + #{map-get($spacers, 6)}));

      .people-content-block {
        grid-gap: map-get($spacers, 6);
        // grid-auto-flow: dense;

        &__images {
          display: contents;

          img {
            width: 100%;
            max-width: none;
          }
        }

        &__image.image--1 {
          grid-column: 1;
          grid-row: 1;
        }

        &__image.image--2 {
          grid-column: 2;
          grid-row: 2;
        }

        &__title {
          margin-top: auto;
        }

        &__main {
          grid-column: 1;
          grid-row: 2;
        }

        &__main__footer {
          margin-top: 0;
          margin-bottom: auto;
        }
      }
    }
  }

  &__photo-slider {
    display: block;

    .spacer-bottom-l {
      padding-bottom: 0;
    }

    .header__content__title {
      opacity: 0;
    }
  }
}
