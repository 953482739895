.presskit-card {
  position: relative;
  cursor: pointer;

  &.no--image,
  .set--selected &,
  &:hover {
    .presskit-card__crop img {
      opacity: .3;
    }

    .presskit-card__content__text {
      opacity: 1;
    }
  }
}

.presskit-card__remove {
	width: 1em;
}

.presskit-card__crop {
  &::before {
    padding-top: 75%;
    background-color: #000;

    .no--image & {
      background-color: theme-color(secondary);
    }
  }
}

.presskit-card__content {
  @include abs(0,0,0,0);

  padding: map-get($spacers, 1);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.presskit-card__icon {
  @include size(24px);
  @include abs(0, 0, null, null);

  background-color: #fff;
  display: none;

  fstr-icon { display: contents; }

  .icon {
    @include abs(50%, null, null, 50%);
    @include size(60%);

    transform: translate(-50%, -50%);
  }

  .set--selected & {
    display: block;
  }
}

.presskit-card__content__text {
  color: white;
  word-break: break-word;
  padding: map-get($spacers, 1);
  font-size: 15px;
  opacity: 0;
}

.presskit-card__type {
  @include abs(null, $spacer, $spacer, null);

  color: #fff;
  text-transform: uppercase;
}

.presskit-card.type--list {
  display: flex;
  background-color: gray-color(600);

  &:hover {
    .presskit-card__crop img {
      opacity: 1;
    }
  }

  .presskit-card__crop {
    max-width: 160px;
    flex: 0 0 25%;
  }

  .presskit-card__type {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  main {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 50px;

    .button {
      @include abs(50%, 0, null, null);

      transform: translateY(-50%);
      margin-left: auto;
      margin-right: 0;
    }
  }

  .presskit-card__content {
    position: relative;
    text-align: left;
    display: block;
  }

  .presskit-card__content__text {
    opacity: 1;
  }
}
