.fstr-cookie-btn {
  @extend .button;
  @extend .button--primary;

  margin-inline-start: 10px;
  margin-top: 10px;
}

// cookie overrides
.app .fstr-cookie-notice {
  --cookie-success-color: #fff;
  --cookie-bg-color: #fff;
  --cookie-muted-color: #fff;
  --cookie-toggle-size: 22px;
  --cookie-notice-bg: #000;

  max-width: 590px;
  border: transparent;
  color: #fff;
  padding: map-get($spacers, 3);
  left: 0;
  bottom: 0;

  @include media-breakpoint-down(sm) {
    padding: map-get($spacers, 2);
    width: 100%;
  }

  .toggle input[type=checkbox]:disabled ~ label .toggle__label-text {
    // font-weight: 500;
    // opacity: .6;
  }

  .toggle label .toggle__switch {
    color: #000;
    border: 1px solid;
    background-color: #d7d1ce;

    &::after {
      top: 1px;
      height: 18px;
      width: 18px;
      border: 1px solid #000;
      background-color: #000;
      left: 1px;
    }
  }

  .toggle input[type=checkbox]:checked ~ label .toggle__switch {
    background-color: #fff;
  }

  .toggle input[type=checkbox]:checked ~ label .toggle__switch::after {
    background-color: #000;
    left: 3px;
  }

  .toggle input[type=checkbox]:disabled ~ label .toggle__switch {
    border-color: var(--cookie-disabled-color);
    opacity: .6;
    pointer-events: none;
    background-color: transparent;

    &::after {
      border-color: #000;
      background-color: #fff;
    }
  }

  &__content {
    font-size: 15px;
  }

  &__title {
    @extend .h3;

    color: inherit;
  }

  &__toggle-row {
    margin-bottom: 1ex;
  }

  .button.toggle-button {
    background: no-repeat;
    box-shadow: none;
    font-size: rem(12);
    padding: 0;
    display: none;
    pointer-events: none;

    span {
      width: auto;
      min-width: 20px;
    }
    // flex-direction: column;
    // color: darken(#84cbba, 10%);
  }

  .button-group {
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;

    .button {
      min-width: 150px;
      font-size: 13px;
      margin-left: 0;
      justify-content: center;
      width: 100%;
      padding-top: map-get($spacers, 2);
      padding-bottom: map-get($spacers, 2);
      height: 35px;
      line-height: 0;
    }

    // .button:not(.button--primary) {
    //   background: transparent;
    //   box-shadow: none;
    // }

    .button--close,
    .button--settings {
      border: 2px solid;

      &:hover {
        background-color: #fff;
        border-color: #fff;
        color: #000;
      }
    }

    // temp:
    .button--close {
      // display: none;
    }

    .button--primary {
      color: #000;
      background-color: #fff;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;

      .button {
        width: 100%;
        margin-top: 1ex;
      }

      .button:not(:last-child),
      .button {
        margin-right: auto;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .toggle-button {
      display: none;
    }
  }
}

.embed-removed {
  @extend .aspect-ratio;

  background-color: theme-color(secondary);
  margin-bottom: map-get($spacers, 4);

  &__inner {
    @extend .aspect-ratio-element;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-wrap: wrap;
    text-align: center;

    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }

  .fstr-cookie-btn.button {
    font-size: .9em;
  }
}
