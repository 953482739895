.jobs-card {
  &__title {
    text-decoration: none;

    button,
    a {
      text-decoration: inherit;
      text-align: left;

      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: opacity .1s ease-in-out, max-height .6s cubic-bezier(0, 1, 0, 1);

    &.set--active {
      max-height: 30000px;
      padding-top: map-get($spacers, 4);
      transition: opacity .1s ease-in-out, max-height .6s ease-in;
    }
  }

  &__footer {
    margin-top: map-get($spacers, 2);

    @include media-breakpoint-up(md) {
      margin-top: map-get($spacers, 3);
    }

    .button {
      text-align: center;
      justify-content: center;
    }

    button {
      margin-right: 1ex;
    }

    &.button-group {
      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
        padding-left: map-get($spacers, 3);
        padding-right: map-get($spacers, 3);

        .button-link {
          padding-top: 1ex;
          padding-bottom: 1ex;
          text-align: left;
        }
      }
    }
  }
}
