@import '@glidejs/glide/src/assets/sass/glide.core';

.glide__bullets {
  display: flex;
}

.glide__bullet {
  @include size(10px, 10px);

  border: 1px solid $white;
  margin-left: map-get($spacers, 1);

  &--active {
    background-color: $white;
  }

  &:first-of-type {
    margin-left: 0;
  }
}

.glide__slide {
  position: relative;
}

.glide__arrow {
  @include abs(50%, null, null, null);
  @include size(50px, 50px);

  transform: translate(0, -50%);
  font-size: 0;

  &--left {
    left: map-get($spacers, 2);
    background: url('/assets/gfx/icon-chevron-left-white.svg') no-repeat center center;
    background-size: 60% 60%;

    @include media-breakpoint-down(sm) {
      background-size: 40% 40%;
      left: map-get($spacers, 1);
    }
  }

  &--right {
    right: map-get($spacers, 2);
    transform: translate(0, -50%);
    background: url('/assets/gfx/icon-chevron-right-white.svg') no-repeat center center;
    background-size: 60% 60%;

    @include media-breakpoint-down(sm) {
      background-size: 40% 40%;
      right: map-get($spacers, 1);
    }
  }
}
