.news-overview {
  &__category {
    padding-bottom: map-get($spacers, 4);

    @include media-breakpoint-up(sm) {
      padding-bottom: map-get($spacers, 5);
    }

    .button-group {
      flex-wrap: wrap;
    }

    .button-link {
      color: gray-color(500);
      margin-right: 2ex;

      &:last-of-type {
        margin-left: 0;
      }

      @media (hover: hover) {
        &:hover {
          color: $black;
          text-decoration: none;
        }
      }

      &.set--active {
        color: $black;
        text-decoration: none;
      }

      &.set--active {
        pointer-events: none;
        cursor: none;
      }
    }
  }

  &__loadmore {
    margin: auto;
    display: block;
  }

  .fixed-footer {
    @extend .type-jumbo;

    display: none;

    @include media-breakpoint-up(xxl) {
      display: block;
      opacity: 0;
      transform: translate3d(0, 400%, 0);
      // transform-origin: left bottom;
      transition: transform .2s ease-in-out, opacity .2s ease-in-out;
      bottom: map-get($spacers, 3);

      &.show--fixed-footer {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }

  .container {
    @include media-breakpoint-between(md,md) {
      max-width: 598px;
    }

    @include media-breakpoint-between(lg,lg) {
      max-width: 90%;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-auto-flow: dense;
    grid-column-gap: map-get($spacers, 2);

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }
  }

  &__grid__item {
    padding-bottom: 0;
    opacity: 0;
    animation: fade-in .4s .8s forwards;
    animation-delay: calc(50ms * var(--news-item-index));
    height: calc(100% - 2 * #{$spacer});

    @include media-breakpoint-down(md) {
      &:nth-child(3) {
        grid-column: span 2;
        grid-row: span 2;

        .news-card__title {
          font-size: 1.25em;
          line-height: 1.25em;
        }
      }

      &:nth-child(4n+5) {
        // background: red;
        grid-row: span 2;
      }
    }

    @include media-breakpoint-up(md) {
      padding-bottom: map-get($spacers, 2);
    }

    @include media-breakpoint-up(lg) {
      &:nth-child(1) {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;

        .news-card__title {
          font-size: 1.25em;
          line-height: 1.25em;
        }
      }

      &:nth-child(7n+7) {
        grid-column: span 2;
        grid-row: span 2;

        .news-card__title {
          font-size: 1.25em;
          line-height: 1.25em;
        }
      }

      &:nth-child(3),
      &:nth-child(8n+8) {
        // background: red;
        grid-row: span 2;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: map-get($spacers, 5);
    padding-bottom: map-get($spacers, 6);

    .button.type--more-news {
      margin-top: map-get($spacers, 4);
    }
  }

  &__list {
    @include media-breakpoint-between(md, md) {
      // @media (min-width: 961px) and (max-width: 1024px) {
      max-width: 550px;
      margin: 0 auto;
    }
  }

  &__list__item {
    border-bottom: 1px solid gray-color(300);

    &:first-child {
      border-top: 1px solid gray-color(300);
    }
  }
}
