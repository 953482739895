.project-overview {
  position: relative;

  &__spacer {
    @include media-breakpoint-between(md, md) {
      padding-bottom: map-get($spacers, 6);
    }
  }

  &__main {
    position: relative;
    overflow-x: hidden;
    min-height: var(--projectsMinHeight);

    @media (hover: hover) {
      overflow: inherit;
    }
  }

  &__main__container {
    min-height: 70vh;

    &.view--list {
      @include media-breakpoint-down(xl) {
        max-width: 100%;
      }
    }
  }

  &__list {
    .col-md-6 {
      @include media-breakpoint-between(md, md) {
        // @media (min-width: 768px) and (max-width: 960px) {
        flex: 0 0 33.333%;
        max-width: 33.333%;
      }
    }
  }

  // @include media-breakpoint-up(xxl) {
  &__filters {
    @extend .people-employees__filters;

    position: relative;
    z-index: 20;

    @include media-breakpoint-between(xl, xl) {
      min-height: 76px;
      margin-bottom: map-get($spacers, 4);
    }
  }

  &__filters__wrapper {
    @include media-breakpoint-between(xl, xl) {
      // position: absolute;
      width: 100%;
      background-color: inherit;
      padding-bottom: 2ex;
    }
  }
  // }

  &__filters__view {
    @extend .people-employees__filters__sort;

    @include media-breakpoint-between(md,lg) {
      @include abs(0, map-get($spacers, 3), null, null);
    }
  }

  &__workfields__item:last-child {
    .project-workfields__content {
      padding-bottom: 0;
    }
  }

  &__header {
    position: relative;

    &__toggle-view {
      @include abs(0,0,null,null);

      transform: translateY(2px);

      .button-link {
        .light & {
          @extend .color--project-gray;
        }

        &:first-of-type {
          margin-right: 1ex;
        }

        &.set--active {
          fill: $white;
          opacity: 1;
        }

        fstr-icon {
          display: contents;
        }

        .icon {
          transform: translateY(2px);
          display: none;
        }

        @include media-breakpoint-down(sm) {
          > span {
            display: none;
          }

          .icon {
            display: block;

            @include size(12px);

            min-width: 4px;
            min-height: 4px;
          }
        }
      }
    }
  }

  &__labels {
    display: flex;
    border-bottom: 1px solid gray-color(500);
    margin-bottom: 0;
    padding-bottom: map-get($spacers, 1);

    &__item {
      flex: 1;
      font-weight: 700;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;

      // &.type--status,
      &.type--types,
      &.type--type,
      &.type--dicipline {
        pointer-events: none;

        .icon {
          display: none;
        }
      }

      &.type--status,
      &.type--type {
        display: none;
      }

      @include media-breakpoint-down(md) {
        &.type--dicipline {
          display: none;
        }
      }

      @include media-breakpoint-up(xl) {
        &.type--type,
        &.type--status {
          display: flex;
        }
      }

      &.set--active {
        &::before {
          width: calc(100% - #{map-get($spacers, 2)});
          height: 0;
          border-bottom: 3px solid;
          content: '';

          @include abs(121%, null, null, 0);
        }

        .icon,
        .button-link {
          display: inline-block;
          opacity: 1;
        }
      }

      .icon {
        @include size(20px);

        // display: none;
        fill: $white;
        opacity: .4;
        transform: translate(1ex, 2px);
      }
    }
  }
}
