app-project-list-item {
  display: contents;
}

.project-list-item {
  .light & {
    @extend .color--project-gray;
  }

  position: relative;
  width: 100%;
  color: currentColor;
  padding-top: $spacer;
  padding-bottom: $spacer;
  border-bottom: 1px solid;

  &.set--open {
    padding-top: 0;
    padding-bottom: 0;
  }

  @include media-breakpoint-up(xl) {
    // color: gray-color(600);
  }

  @media (hover: hover) {
    &:hover {
      color: $white;
    }
  }

  &__content {
    display: flex;
    position: relative;
    width: 100%;
    overflow: hidden;

    .set--open & {
      padding-top: $spacer;
    }

    &__item {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: map-get($spacers, 3);

      &.diciplines span {
        margin-left: 1ex;

        &::after {
          content: ',';
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child::after {
          content: '';
        }
      }

      &.types,
      &.type,
      &.status {
        display: none;
      }

      @include media-breakpoint-down(md) {
        &.diciplines {
          display: none;
        }
      }

      @include media-breakpoint-up(xl) {
        &.status {
          display: flex;
        }

        &.type,
        &.types {
          display: block;
        }
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      color: inherit;
      z-index: 10;

      .project-list-item__crop.type--header {
        opacity: 1;
      }

      &.set--open {
        .project-list-item__crop.type--header {
          opacity: 0;
        }

        .project-list-item__images__item--type-link {
          // opacity: 1;

          .button--border {
            pointer-events: all;
          }
        }
      }
    }
  }

  &__images {
    display: flex;
    max-height: 0;
    overflow: hidden;
    position: relative;
    transition: max-height .6s cubic-bezier(0, 1, 0, 1);

    .set--open & {
      @extend .spacer-bottom-xs;

      max-height: 1000px;
      transition: max-height .4s ease-in;
    }

    &__item {
      flex: 0 0 33.3333%;
      padding-right: map-get($spacers, 2);

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        display: none;
      }

      @include media-breakpoint-between(lg,lg) {
        flex: 0 0 25%;

        &:nth-child(4) {
          display: block;
        }
      }

      @include media-breakpoint-up(xl) {
        flex: 0 0 16.66%;

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          display: block;
        }
      }

      &--type-link {
        @include abs(0,0,0,0);

        display: flex;
        justify-content: center;
        align-items: flex-end;
        text-align: center;
        background-color: rgba($black, .8);
        opacity: 0;
        transition: opacity .2s ease-in-out;

        .button--border {
          justify-content: center;
          pointer-events: none;
        }
      }
    }
  }

  &__crop {
    @extend .aspect-ratio;

    &::before {
      padding-top: 69%;
    }

    img {
      @extend .aspect-ratio-element;
    }

    &.type--header {
      max-width: 735px;
      width: 40%;
      pointer-events: none;
      opacity: 0;
      transition: opacity .01s ease-in-out;

      @include abs(null, 0, 100%, null);
    }
  }
}
