app-purpose-teaser {
  display: contents;
}

.purpose-teaser {
  @include size(100%);

  position: relative;

  &__crop {
    @extend .aspect-ratio;

    &::before {
      padding-top: 75%;

      @include media-breakpoint-down(sm) {
        padding-top: 100%;
      }
    }

    img {
      @extend .aspect-ratio-element;
    }
  }

  &__title {
    @include abs(null, 0, 0, 0);

    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);

    @include media-breakpoint-up(sm) {
      padding-left: map-get($spacers, 3);
      padding-right: map-get($spacers, 3);
    }
  }

  &__link {
    max-width: none;
    width: 100%;

    // background-color: rgba($black, .1);
  }
}
