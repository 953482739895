.press-snackbar {
  @include fxd(100%, null, null, 50%);

  transform: translate3d(-50%, 0, 0);
  z-index: 90;
  max-width: 680px;
  padding: 0 map-get($spacers, 2);
  margin-top: 1px;
  transition: transform .3s ease-in-out;

  @include media-breakpoint-down(sm) {
    margin-left: -1px;
    width: calc(100% + 2px);
  }

  @include media-breakpoint-up(sm) {
    padding: 0 map-get($spacers, 4);
  }

  &.set--active {
    transform: translate3d(-50%, -54px, 0);
  }

  &.set--collapse {
    transform: translate3d(-50%, -100%, 0);
  }
}

.press-snackbar__main__form,
.press-snackbar__main__selected-files {
  white-space: normal;
  height: 60vh;
  max-height: calc(90vh - 150px);
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.press-snackbar__header {
  display: flex;
  height: 54px;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  cursor: pointer;

  fstr-icon { display: contents; }

  .icon {
    @include size(20px);

    .set--collapse & {
      transform: rotate(-180deg);
    }
  }
}

.press-snackbar__main {
  position: relative;
  overflow: hidden;
}

.press-snackbar__main__wrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  width: calc(200% + #{map-get($spacers, 2)});
  align-items: flex-start;
  justify-content: flex-end;
  transition: transform .3s ease-in-out;

  @include media-breakpoint-down(sm) {
    width: 200%;
  }

  &.is--end {
    transform: translate3d(-50%, 0, 0);
  }

  .item {
    flex: 1;
    position: relative;
  }
}

.press-snackbar__main__tabs .tab {
  opacity: .6;

  &.set--active {
    opacity: 1;
  }
}

.snackbar-overlay {
  @include fxd(0,0,0,0);

  background-color: #000;
  pointer-events: none;
  opacity: 0;

  &.is--active {
    opacity: 0;
    pointer-events: all;
    z-index: 80;
  }
}
