.block-link {
  @include abs(0, 0, 0, 0);

  text-indent: 400%;
  white-space: nowrap;
  display: block;
  z-index: 20;
  cursor: pointer;
}

.sr-text {
  @include size(1,1);

  font-size: 0;
  color: transparent;
  overflow: hidden;
}
