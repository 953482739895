/*
* Horizontal scrollable list
* Usage: .subnav { @mixin mobile-scrollable }
* NOTE: Nested items should be BEM, otherwise you should update the selector variable.. 
*/

@mixin horizontal-scroll (
    $selector: '&__item',
    $gutter: 15px
) {
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  flex: 1;

  &__item {
    display: flex;
    margin-right: rem($gutter);

    &:last-child::after {
      content: '';
      width: rem($gutter);
      display: block;
    }
  }
}
