// We use the Responsive font-sizing of Bootstrap by enabling the responsive-font-sizes boolean.
$enable-responsive-font-sizes: false; // Leave this on true

// Set the maximum font-sizes here,
// the Boostrap RFS takes care of the rest

/*
$font-sizes: (
  jumbo-xl: 92pt, // NOTE: vw takes over on very large screens
  jumbo: 82pt,
  h1: 30pt,
  h2: 25pt,
  h3: 24pt,
  h4: 20pt,
  h5: 18pt,
  h6: 16pt,
  body: 16pt,
  body-medium: 18pt,
  body-large: 20pt,
  // You can add more key value pairs,
    // like an intro text for example:
    // intro: 18px,
    //
    // Use it like this:
    // @include get-font-size(intro);,
);
*/

$font-sizes: (
  jumbo-l: 125pt,
  jumbo-m: 92pt,
  jumbo-s: 60pt,
  h1-l: 92pt,
  h1-m: 60pt,
  h1-s: 35pt,
  h2-l: 40pt,
  h2-m: 32pt,
  h2-s: 24pt,
  h3-l: 20pt,
  h3-m: 20pt,
  h3-s: 18pt,
  large-text-l: 25pt,
  large-text-m: 20pt,
  large-text-s: 18pt,
  medium-text-l: 20pt,
  medium-text-m: 16pt,
  medium-text-s: 14pt,
  small-text-l: 16pt,
  small-text-m: 16pt,
  small-text-s: 14pt
);

$line-heights: (
  jumbo-l: 112.5pt,
  jumbo-m: 82.8pt,
  jumbo-s: 54pt,
  h1-l: 82.8pt,
  h1-m: 54pt,
  h1-s: 31.5pt,
  h2-l: 40pt,
  h2-m: 32pt,
  h2-s: 24pt,
  h3-l: 24pt,
  h3-m: 24pt,
  h3-s: 21.6pt,
  large-text-l: 30pt,
  large-text-m: 24pt,
  large-text-s: 21.6pt,
  medium-text-l: 24pt,
  medium-text-m: 19.2pt,
  medium-text-s: 18.2pt,
  small-text-l: 19.2pt,
  small-text-m: 19.2pt,
  small-text-s: 18.2pt
);
