
:root {
  #{--hamburger-width}: 20px;
  #{--hamburger-height}: 2px;
  #{--hamburger-spacing}: 6px;

  @include media-breakpoint-up(md) {
    #{--hamburger-width}: 25px;
    #{--hamburger-height}: 2px;
    #{--hamburger-spacing}: 8px;
  }

  @include media-breakpoint-up(xl) {
    #{--hamburger-width}: 35px;
    #{--hamburger-height}: 3px;
    #{--hamburger-spacing}: 10px;
  }
}

.hamburger-toggle {
  $width: var(--hamburger-width);
  $height: var(--hamburger-height);
  $spacing: var(--hamburger-spacing);
  $color: $black;
  $timing: .2s;
  $easing: cubic-bezier(.23, 1, .32, 1);

  width: $width;
  height: calc(#{$height} + (#{$spacing} * 3));
  cursor: pointer;
  display: flex;
  align-items: center;

  &__lines {
    position: relative;
    transform: translateY(calc(#{$height} * 2));
    background: $color;

    &,
    &::before,
    &::after {
      width: $width;
      height: $height;
      display: inline-block;
      content: '';
      background: $color;
    }

    &::before {
      @include abs(auto, 0, $spacing, 0);

      transition: background $timing, bottom $timing $timing $easing, transform $timing $easing;
    }

    &::after {
      @include abs($spacing, 0, auto, 0);

      transition: background $timing, top $timing $timing $easing, transform $timing $easing;
    }

    &--active {
      // stylelint-disable declaration-no-important
      background: transparent !important;

      &::before {
        bottom: 0;
        transition: background $timing, bottom $timing $easing, transform $timing $timing $easing;
        transform: rotate(45deg);
      }

      &::after {
        top: 0;
        transition: background $timing, top $timing $easing, transform $timing $timing $easing;
        transform: rotate(-45deg);
      }
    }
  }

  &--light &__lines {
    &,
    &::before,
    &::after {
      background: $white;
    }
  }
}
