.people-employees {
  position: relative;
  min-height: var(--projectsMinHeight);

  @include media-breakpoint-down(sm) {
    overflow-x: hidden;
  }

  @include media-breakpoint-up(xxl) {
    padding-top: 0;

    &.has--top-spacing {
      margin-top: map-get($spacers, 7);
    }
  }

  &__loadmore {
    justify-content: center;
  }

  &__filters__wrapper {
    // position: absolute;
    width: 100%;
    background-color: $white;
    padding-bottom: 2ex;
  }

  &__filters {
    z-index: 10;
    position: relative;

    @include media-breakpoint-between(xl,xl) {
      min-height: map-get($spacers, 6);
      margin-bottom: map-get($spacers, 4);
    }

    &.set--active-filter {
      min-height: 80px;
    }

    .container-fluid {
      display: flex;

      .filter-bar {
        flex: 1;

        @include media-breakpoint-up(xxl) {
          @include abs(0, null, null, map-get($spacers, 4));

          max-width: 250px;
        }
      }
    }
  }

  &__filters__sort {
    @include media-breakpoint-down(md) {
      position: absolute;
      right: map-get($spacers, 2);
    }

    @include media-breakpoint-up(xxl) {
      @include abs(36px,map-get($spacers, 4),null,null);
    }

    .form-label {
      margin-left: 1ex;
    }

    input[type='checkbox'],
    input[type='radio'] {
      display: none;

      &:checked + span {
        color: inherit;
        opacity: 1;
      }
    }

    label span {
      color: theme-color(secondary);

      .light & {
        @extend .color--project-gray;
      }
    }

    label:hover {
      span { color: inherit; }
    }
  }

  @include media-breakpoint-between(xl,xl) {
    .filter-bar__filters__list:first-of-type {
      flex: 0 0 60%;
      display: flex;
      flex-wrap: wrap;

      dd:first-of-type,
      dt {
        flex: 0 0 100%;
      }

      dd {
        flex: 0 0 45%;
      }
    }

    .filter-bar__filters__list:last-of-type {
      flex: 0 0 25%;
    }
  }
}
