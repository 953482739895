%fixed-footer {
  @extend h1;

  position: fixed;
  bottom: 0;
  left: map-get($spacers, 4);
  z-index: 20;
  // opacity: 0;
  // animation: fade-in .4s .8s forwards;

  @include media-breakpoint-down(sm) {
    margin: rem(20px);
  }
}

.fixed-footer {
  @extend %fixed-footer;
}
