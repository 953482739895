#{$all-text-inputs},
input[type='file'],
select {
  // font-family: $headings-font-family;
  font-weight: inherit;
  display: inline-block;
  outline: none;
  -webkit-appearance: none;
  // will-change: background-color;
  border-width: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  color: inherit;

  &::placeholder {
    color: gray-color(500);
    font-weight: inherit;
    text-transform: none;
    font: {
      // size: .9em;
      style: none;
    }
  }

  &:active,
  &:focus {
    outline: none;
  }

  &::-ms-clear {
    display: none;
  }

  &.error {
    color: color(red);
  }

  &:read-only {
    border-bottom: 0;
  }

  &:disabled {
    pointer-events: none;
    opacity: .7;
  }
}

input[type='search']::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;

  @include size(15px);

  background: url('/assets/gfx/icon-cross-gray.svg') center center no-repeat;
  background-size: 80% 80%;
  content: 'x';
}

label {
  margin-bottom: 0;
  cursor: pointer;
}
