$white: #fff;
$black: #000;

$colors: (
  blue: #007bff,
  indigo: #6610f2,
  purple: #6f42c1,
  pink: #ed5ca1,
  red: #dc3545,
  orange: #fd7e14,
  yellow: #ffc107,
  green: #28a745,
  teal: #20c997,
  cyan: #17a2b8,
);

$theme-colors: (
  primary: $black,
  // Primary tints from 100-900:
  // primary-100: #123456,
  secondary: #d7d1ce,
  tertiary: $white,
  // Secondary tints from 100-900:
  // secondary-100: #123456,
  success: color(green),
  info: color(blue),
  warning: color(orange),
  danger: color(red),
  light: $white,
  dark: $black,
  pink: color(pink),
);

$grays: (
  900: #212529,
  600: #75777a,
  500: #d7d1ce,
  400: theme-color(secondary),
  300: #d7d1ce,
  250: #d7d1ce,
  200: #d8d8d8,
  100: #f8f9fa,
);
