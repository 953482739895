.project-content-block {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: map-get($spacers, 2);
    grid-auto-flow: dense;
  }

  &__main__footer {
    .block-content__quote {
      margin-bottom: 0;
    }

    .button {
      margin-top: map-get($spacers, 3);
    }

    @include media-breakpoint-down(sm) {
      padding-top: map-get($spacers, 4);
    }
  }

  &__text {
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &__image__crop {
    @extend .aspect-ratio;

    max-height: 90vh;
    cursor: pointer;
    background-color: gray-color(100);

    img {
      @extend .aspect-ratio-element;

      animation: fade-in .2s ease-in-out;
    }
  }

  &__image__caption {
    // @extend .image-caption;
    font-size: .8em;
    position: relative;
    max-width: 580px;
    margin-top: 1ex;

    @include media-breakpoint-down(sm) {
      padding: 0 map-get($spacers, 2);
    }
  }

  @include media-breakpoint-down(sm) {
    &__image {
      margin-left: -12px;
      margin-right: -12px;
      margin-bottom: map-get($spacers, 3);
      grid-column: span 2;

      &__crop::before {
        padding-top: 100%;
      }
    }
  }

  @include media-breakpoint-between(md, lg) {
    .project-content-block__image__crop::before {
      padding-top: 74.9%;
    }
  }

  //spacing
  @include media-breakpoint-between(md, lg) {
    &__main {
      grid-column: span 2;
      max-width: 650px;
      margin: 0 auto;
      padding-top: map-get($spacers, 4);

      &__footer {
        padding-top: map-get($spacers, 6);
      }
    }
  }

  &__main {
    @include media-breakpoint-between(md, md) {
      max-width: 490px;
    }
  }

  @include media-breakpoint-up(xl) {
    grid-row-gap: map-get($spacers, 6);

    &.type--past-block-one-image {
      // .project-page__blocks__list__item:nth-child(3n+3) & {
      margin-top: calc(0px - #{map-get($spacers, 6)} - var(--projectMain));
    }

    &__image {
      display: flex;
      flex-direction: column;
    }

    &__crop {
      width: 100%;
    }

    &__image--1 {
      grid-column: span 2;
      align-items: flex-end;
      text-align: right;

      figure {
        // width: 100%;
        // max-width: map-get($container-max-widths, xl);
        width: 90%;
        max-height: 90vh;
        max-width: 1200px;

        &::before {
          padding-top: 75%;
        }

        .type--past-block-one-image & {
          // .project-page__blocks__list__item:nth-child(3n+3) & {
          grid-column: auto;
          max-width: calc(50% - #{map-get($spacers, 4)});
          height: calc(var(--projectMain) + #{map-get($spacers, 9)});
        }
      }

      // .project-page__blocks__list__item:nth-child(2n+2) .type--two-images & {
      //   grid-column: span 1;

      //   figure {
      //     width: 92%;
      //     margin-right: auto;
      //     margin-left: 0;
      //     max-width: none;
      //   }
      // }
    }

    .project-content-block__image__caption {

    }

    &__image--2 {
      .type--past-block-one-image & {
        // .project-page__blocks__list__item:nth-child(3n+3) & {
        margin-top: -#{map-get($spacers, 9) *1};
      }

      .type--one-image & {
        display: none;
      }

      .project-content-block__image__crop {
        max-width: 740px;
        width: 80%;
      }

      .project-content-block__image__crop::before {
        padding-top: 133%;
      }
    }

    &__main {
      max-width: 450px;
      margin: 0 auto 0 map-get($spacers, 6);
      display: flex;
      // min-height: 400px;
      flex-direction: column;

      .project-page__blocks__list__item:nth-child(2n+2) & {
        // padding-bottom: map-get($spacers, 4);
      }

      &__footer {
        margin-bottom: 0;
        margin-top: auto;
        padding-top: map-get($spacers, 4);
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    &__main {
      max-width: 610px;
    }
  }

  &__video {
    grid-column: span 2;
    width: 100%;

    @include media-breakpoint-down(sm) {
      width: calc(100% + 24px);
      margin-bottom: map-get($spacers, 3);
      margin-left: -#{map-get($spacers, 2)} !important;
      margin-right: -#{map-get($spacers, 2)};
    }

    @include media-breakpoint-between(md, lg) {
      margin-bottom: map-get($spacers, 3);
      width: 100%;
    }

    @include media-breakpoint-up(sm) {
      grid-column: span 2;
      max-width: 1200px;
    }

    @include media-breakpoint-up(xl) {
      // width: 70vw;
      // margin: auto;
      .project-page__blocks__list__item:nth-child(odd) & {
        margin-left: auto;
        margin-right: 0;
        text-align: right;
      }
    }

    .project-content-block__image__caption {
      padding-right: 12px;
      padding-left: 12px;
      margin-top: 5px;
      display: inline-block;

      @include media-breakpoint-up(md) {
        padding: 0;
      }

      @include media-breakpoint-up(xl) {
        float: right;
      }
    }

    .header {
      fstr-html5-video {
        @include size(100%);
        @include abs(0,0,0,0);

        display: block;
        z-index: 20;
      }

      &__buttons,
      &__content {
        display: none;
      }

      &__crop {
        height: auto;
        padding-top: 56.26%;
        // padding-top: 0;

        &::before {
          padding-top: 56.25%;
          height: 0;
          position: relative;
          width: 100%;
          display: block;
        }
      }
    }
  }
}

.project-page__blocks__list > li:nth-child(odd) {
  .project-content-block__video {
    margin-left: auto;
  }
}
