.employee-card {
  position: relative;

  @include size(100%);

  &__crop {
    @extend .aspect-ratio;

    margin-bottom: 0;

    &::before {
      padding-top: 71%;
    }

    img {
      @extend .aspect-ratio-element;

      display: none;
    }

    // &.set--inview {
    //   img {
    //     display: inline-block;
    //     animation: fade-in .3s ease-in-out;
    //   }
    // }
  }

  &__content {
    padding-top: $spacer;
    padding-bottom: $spacer;
    // line-height: 1.4;
    line-height: 19.2px;
  }

  &__title {
    font-family: $font-family-sans-serif;
    font-size: inherit;
    margin-bottom: 0;
    line-height: inherit;
    font-weight: normal;

    em::before { content: ', '; margin-left: -3px; }
  }

  &__email {
    a {
      // text-decoration: none;
    }
  }
}
