// Headings

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.type-jumbo,
.type-jumbo-xl {
  display: block;
  margin-bottom: .5em;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  // color: $headings-color;
}

.h3,
.h4,
.h5,
blockquote {
  font-family: $headings-font-family-secondary;
  font-weight: normal;
}

blockquote {
  @extend .type-body-medium;

  &::before {
    content: '';
  }

  &::after {
    content: '';
  }
}

@for $i from 1 through 6 {
  .h#{$i},
  h#{$i} {
    @include get-font-size(h3-s);
    @include get-line-height(h3-s);

    @include media-breakpoint-up(sm) {
      @include get-font-size(h3-m);
      @include get-line-height(h3-m);
    }

    @include media-breakpoint-up(lg) {
      @include get-font-size(h3-l);
      @include get-line-height(h3-l);
    }
  }
}

// Emphasis
small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-normal;
}

.heading-secondary {
  font-family: $headings-font-family-secondary;
  font-weight: normal;
}

.type {
  &-body-large {
    @include get-font-size(large-text-s);
    @include get-line-height(large-text-s);

    // font-weight: 600;

    @include media-breakpoint-up(sm) {
      @include get-font-size(large-text-m);
      @include get-line-height(large-text-m);
    }

    @include media-breakpoint-up(xl) {
      @include get-font-size(large-text-l);
      @include get-line-height(large-text-l);
    }
  }

  &-body-medium {
    @include get-font-size(medium-text-s);
    @include get-line-height(medium-text-s);

    @include media-breakpoint-up(sm) {
      @include get-font-size(medium-text-m);
      @include get-line-height(medium-text-m);
    }

    @include media-breakpoint-up(lg) {
      @include get-font-size(medium-text-l);
      @include get-line-height(medium-text-l);
    }
  }

  &-body-small {
    @include get-font-size(small-text-s);
    @include get-line-height(small-text-s);

    @include media-breakpoint-up(sm) {
      @include get-font-size(small-text-m);
      @include get-line-height(small-text-m);
    }

    @include media-breakpoint-up(lg) {
      @include get-font-size(small-text-l);
      @include get-line-height(small-text-l);
    }
  }

  &-jumbo {
    @include get-font-size(h2-s);
    @include get-line-height(h2-s);

    text-transform: uppercase;
    letter-spacing: rem(-1px);

    @include media-breakpoint-up(lg) {
      @include get-font-size(h2-m);
      @include get-line-height(h2-m);
    }

    @include media-breakpoint-up(xl) {
      @include get-font-size(h2-l);
      @include get-line-height(h2-l);
    }
  }

	&-jumbo-m {
		@include font-size(32);
    @include get-line-height(h2-l);

    text-transform: uppercase;
    letter-spacing: rem(-1px);


	}

  &-jumbo-l {
		@include get-font-size(h1-s);
    @include get-line-height(h1-s);

    text-transform: uppercase;
    letter-spacing: rem(-1px);

    @include media-breakpoint-up(md) {
      @include get-font-size(jumbo-s);
      @include get-line-height(jumbo-s);
    }

    //@include media-breakpoint-up(xl) {
    //  @include get-font-size(jumbo-m);
    //  @include get-line-height(jumbo-m);
    //}

    //@include media-breakpoint-up(xxl) {
    //  @include get-font-size(jumbo-l);
    //  @include get-line-height(jumbo-l);

    //  letter-spacing: -2px;
    //}
  }

  &-jumbo-xl {
    @include get-font-size(h1-s);
    @include get-line-height(h1-s);

    text-transform: uppercase;
    letter-spacing: rem(-1px);

    @include media-breakpoint-up(md) {
      @include get-font-size(jumbo-s);
      @include get-line-height(jumbo-s);
    }

    @include media-breakpoint-up(xl) {
      @include get-font-size(jumbo-m);
      @include get-line-height(jumbo-m);
    }

    @include media-breakpoint-up(xxl) {
      @include get-font-size(jumbo-l);
      @include get-line-height(jumbo-l);

      letter-spacing: -2px;
    }
  }
}

.spacer-top-xxs {
  padding-top: $spacer;
}

.spacer-bottom-xxs {
  padding-bottom: $spacer;
}

.spacer-top-xs {
  padding-top: $spacer;

  @include media-breakpoint-up(sm) {
    padding-top: map-get($spacers, 2);
  }
}

.spacer-bottom-xs {
  padding-bottom: $spacer;

  @include media-breakpoint-up(sm) {
    padding-bottom: map-get($spacers, 2);
  }
}

.spacer-top-s {
  padding-top: map-get($spacers, 2);

  @include media-breakpoint-up(xl) {
    padding-top: map-get($spacers, 3);
  }
}

.spacer-bottom-s {
  padding-bottom: map-get($spacers, 2);

  @include media-breakpoint-up(xl) {
    padding-bottom: map-get($spacers, 3);
  }
}

.spacer-top-m {
  padding-top: map-get($spacers, 3);

  @include media-breakpoint-up(xl) {
    padding-top: map-get($spacers, 4);
  }
}

.spacer-bottom-m {
  padding-bottom: map-get($spacers, 3);

  @include media-breakpoint-up(xl) {
    padding-bottom: map-get($spacers, 4);
  }
}

.spacer-top-l {
  padding-top: map-get($spacers, 4);

  @include media-breakpoint-up(xl) {
    padding-top: map-get($spacers, 6);
  }
}

.spacer-bottom-l {
  padding-bottom: map-get($spacers, 4);

  @include media-breakpoint-up(xl) {
    padding-bottom: map-get($spacers, 6);
  }
}

.spacer-top-xl {
  padding-top: map-get($spacers, 7);

  @include media-breakpoint-up(xl) {
    padding-top: map-get($spacers, 8);
  }
}

.spacer-bottom-xl {
  padding-bottom: map-get($spacers, 7);

  @include media-breakpoint-up(xl) {
    padding-bottom: map-get($spacers, 8);
  }
}

.spacer-top-xxl {
  padding-top: map-get($spacers, 9);
}

.spacer-bottom-xxl {
  padding-bottom: map-get($spacers, 9);
}

.white-space-pre {
  white-space: pre;
}


.text-transform-none {
	text-transform: none!important;
}

.lh-1 {
	line-height: 1 !important;
}

.lh-sm {
	line-height: 1.1 !important;
}

.lh-base {
	line-height: 1.5 !important;
}

.lh-lg {
	line-height: 1.8 !important;
}