// body class
.main-nav--active {
  overflow: hidden;
}

// util class to reset fixed no height
.topbar-spacer {
  height: rem(120px);

  @include media-breakpoint-down(md) {
    height: rem(100px);
  }
}