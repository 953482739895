// I want to propose:
// Max 3 levels
// BEM & SCSS rules

.header {
  @include size(100%, auto); //ios Fix ViewHeight

  // background: $black;
  position: relative;

  &.type--404 {
    height: calc(100vh - 180px);
    background: white;

    @include media-breakpoint-down(sm) {
      height: calc(100vh - 110px);
    }
  }

  &.set--inverted {
    background-color: $white;

    .header__crop img {
      opacity: .7;
    }
  }

  &.set--image-contain {
    .header__crop {
      padding: 0;

      img {
        max-height: calc(100vh - 20px);
        margin: auto;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &__crop__wrapper {
    transform: translate3d(0, 0, 0);
    will-change: transform;
    overflow: hidden;
    background-color: $black;
  }

  &__crop {
    position: relative;
    z-index: 14;
    margin: 0;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      padding-top: 100%;
    }

    @include media-breakpoint-up(sm) {
      padding-top: 50%;
    }

    @include media-breakpoint-up(xxl) {
      padding-top: calc(50% - #{map-get($spacers, 6)});
    }

    .aspect-ratio-element::before {
      @include size(100%, 100%);
      @include abs(0, 0, 0, 0);

      z-index: 10;
      background: $black;
      content: '';
      opacity: .1;
      transition: opacity .3s ease-in-out;

      .set--inverted & {
        background: $white;
      }
    }

    &--audio-on {
      .aspect-ratio-element::before {
        opacity: 0;
      }
    }

    .header__image,
    .fstr-html5-video__container {
      @extend .aspect-ratio-element;

      animation: fade-in .3s ease-in-out forwards;
      position: absolute !important;
      transition: opacity .15s ease-in-out;

      video {
        outline: none;
      }
    }

    .header__image {
      .set--image-contain & {
        object-fit: contain;
      }
    }

    // .set--inview & .header__image {
    //   animation: fade-in .3s .3s ease-in-out both;
    //   // opacity: 1;
    //   // filter: blur(0);
    // }

    .header__image.video--loaded {
      animation: fade-out .3s .3s ease-in-out both;
      z-index: 10;
      pointer-events: none;
    }

    fstr-html5-video {
      @include size(100%);

      top: 0;
      left: 0;
      position: absolute;
    }

    .fstr-html5-video__container {
      padding-bottom: 0 !important;
      height: 100% !important;

      video {
        object-fit: cover;
      }
    }
  }

  &__content {
    @include abs(0, 0, 0, 0);

    z-index: 15;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    color: $white;

    .set--inverted & {
      color: $black;
    }

    .set--audio & {
      opacity: 0;
    }

    &__subtitle {
      color: inherit;
      pointer-events: none;
    }

    &__title {
      // @extend %splitting;
      pointer-events: none;
      text-align: center;
      color: inherit;
      white-space: pre-line;
      margin-bottom: 0;

      @include media-breakpoint-up(sm) {
        max-width: 80vw;
      }

      @include media-breakpoint-down(sm) {
        font-size: 9vw;
        line-height: 8vw;
      }

      // @include media-breakpoint-down(sm) {
      //   font-size: pt-to-px(map-get($font-sizes, h2-l));
      //   line-height: pt-to-px(map-get($line-heights, h2-l));
      // }
    }

    .set--animation & .splitting .char {
      // @extend %splitting-active;
    }
  }

  &__buttons {
    @extend .spacer-bottom-m;

    @include abs(null, null, 0, 0);

    z-index: 20;
    width: 100%;
    fill: $white;
    color: $white;

    .set--inverted & {
      fill: $black;
      color: $black;
    }

    .container-fluid,
    .container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .button--border {
      opacity: 1;

      .set--audio & {
        opacity: 0;
        pointer-events: none;
      }
    }

    .button-audio {
      position: absolute;
      left: $spacer*4;
      bottom: 0;

      @include media-breakpoint-down(sm) {
        top: 0;
        left: 2*$spacer;
        bottom: auto;
        transform: translateY(-100%);
      }
    }
  }

  &__scroll-button,
  &__toggle-audio {
    position: relative;

    @include size(45px, 45px);

    fstr-icon {
      width: 45px;
    }
  }

  &__scroll-button {
    margin: 0 auto;
    animation: slide-in-bottom .3s 1s ease-in-out both;

    fstr-icon {
      @include abs(50%, null, null, 50%);

      transform: translate(-50%, -50%);

      .icon {
        transform: translateY(-5px);
        animation: fade-out 1s .3s ease infinite both;
      }

      & + fstr-icon {
        .icon {
          transform: translateY(5px);
          animation-delay: .6s;
        }
      }
    }
  }

  &__toggle-audio {
    @extend .icon-white;

    fstr-icon {
      @include abs(50%, null, null, 50%);

      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity .2s ease-in-out;

      &.icon--audio-on {
        opacity: 1;
      }
    }

    &--audio-on fstr-icon {
      &.icon--audio-on {
        opacity: 0;
      }

      &.icon--audio-off {
        opacity: 1;
      }
    }
  }

  &__footer {
    @extend .spacer-bottom-m;

    @include abs(null, null, 0,50%);

    width: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    text-align: center;
    overflow: hidden;
    z-index: 20;
    color: $white;
    transform: translateX(-50%);
    // animation: fade-in .3s 1.3s ease-in-out both;

    @include media-breakpoint-down(sm) {
      max-width: 80vw;
      white-space: initial;
    }

    @include media-breakpoint-between(md,md) {
      max-width: 512px;
    }

    @include media-breakpoint-between(lg, lg) {
      max-width: 80%;
    }

    @include media-breakpoint-between(xl, xl) {
      padding-left: 0;
      padding-right: 0;
      max-width: 1140px;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 1500px;
    }

    p {
      margin-bottom: 0;
    }
  }

  &.no--content {
    .header__crop .aspect-ratio-element::before {
      display: none;
    }
  }
}

.header--no-image {
  @include size(100vw, calc(var(--vh, 1vh) * 30));

  .header__crop {
    .aspect-ratio-element::before {
      opacity: 0;
    }
  }

  .header__content__title {
    color: $black;
    transform: translate3d(0, 100px, 0);
  }
}

.header__video__button {
  @include abs(0,0,0,0);
  @include size(100%);

  z-index: 100;
  background-color: rgba(#000, .5);

  button {
    @include abs(50%, null,null, 50%);

    transform: translate(-50%, -50%);
    color: white;
    min-width: 100px;
    fill: currentColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .7em;
    text-transform: uppercase;

    strong {
      position: absolute;
      top: 100%;
      // opacity: 0;
    }

    &:hover strong {
      opacity: 1;
    }
  }

  .icon {
    @include size(50px);

    display: block;
    margin-bottom: $spacer;
  }
}
