.search {
  position: relative;
  z-index: z-index(nav) - 1;

  @include media-breakpoint-up(sm) {
    min-height: calc(100vh - 120px);
  }

  &__muted {
    color: gray-color(250);
  }

  &__header {
    width: 100%;
    position: relative;

    @include media-breakpoint-down(sm) {
      // margin-top: -26px;

      &.set--empty {
        height: calc(100vh - 170px);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: map-get($spacers, 3);

        .type-jumbo {
          font-size: 28px;
          max-width: 60%;
          text-align: center;
          padding-left: 1px;
          margin: auto;
          display: none;
        }
      }
    }

    @include media-breakpoint-between(md, md) {
      margin-top: -30px;

      .type-jumbo {
        font-size: 32px;
      }
    }

    @include media-breakpoint-between(lg, lg) {
      margin-top: -35px;
    }

    @include media-breakpoint-up(xl) {
      margin-top: -32px;
    }

    .search__input {
      width: 100%;
      text-align: center;
      font-size: 45px;

      &::placeholder {
        opacity: 0;
        color: gray-color(250);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 28px;
        white-space: pre-wrap;
        text-align: center;
        font: {
          family: $headings-font-family;
        }
      }

      @include media-breakpoint-down(sm) {
        height: 200px;

        &::placeholder {
          opacity: 1;
        }
      }

      @include media-breakpoint-up(md) {
        font-size: 78px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 120px;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 162px;
      }

      &-wrapper {
        @include media-breakpoint-down(sm) {
          padding-top: 0;
        }
      }
    }

    &.set--submitted {
      animation: fade-in .3s ease-in-out both;

      .search__input-wrapper {
        display: none;
      }
    }
  }

  &__main {
    // min-height: 80vh;
    display: none;
    margin: 0 auto;

    .set--submitted & {
      display: block;
    }

    @include media-breakpoint-between(md, md) {
      .container-xxl {
        max-width: 598px;
      }
    }

    @include media-breakpoint-between(lg, lg) {
      .container-xxl {
        max-width: 960px;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__reset-button {
    // @include fxd($spacer*10,15px,null,null);

    // z-index: 1000;
    animation: fade-in .3s .6s ease-in-out both;
    fill: gray-color(250);

    @include media-breakpoint-down(sm) {
      // right: 1px;
    }

    @include media-breakpoint-between(md,lg) {
      // right: map-get($spacers, 3);
      // transform: translateX(-2px);
    }

    @include media-breakpoint-up(xl) {
      // top: $spacer*12;
      // right: map-get($spacers, 3);
      // transform: translateX(-5px);
    }

    @include media-breakpoint-up(xxl) {
      // top: $spacer*12;
      // right: map-get($spacers, 4);
      // transform: translateX(-3px);
    }

    .icon {
      @include size(25px);

      @include media-breakpoint-down(sm) {
        @include size(18px);
      }

      @include media-breakpoint-between(md,lg) {
        @include size(20px);
      }
    }
  }

  &__projects,
  &__news,
  &__employees {
    position: relative;
    min-height: 100px;
  }

  &__results {
    position: relative;
    opacity: 0;
    max-height: 100px;

    &.set--active {
      animation: fade-in .3s .6s ease-in-out both;
      transition: max-height .3s .6s linear;
      max-height: 200000px;
    }
  }

  &__results-count {
    animation: fade-in .3s  ease-in-out both;
  }

  &__loading {
    position: absolute;
    transform: translateY(100%);
    top: 0;
    animation: fade-out .3s .6s ease-in-out both;
    // // position: absolute;

    &.set--active {
      animation: fade-in .3s ease-in-out both;
    }
  }

  &__projects,
  &__news {
    // @include media-breakpoint-up(md) {
    margin-bottom: map-get($spacers, 6);
    // }
  }

  &__projects .related-projects {
    @include media-breakpoint-down(sm) {
      .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    @include media-breakpoint-between(md,lg) {
      .col-12 {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    @include media-breakpoint-up(xl) {
      .col-12 {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }

  &__news .related-news {
    @include media-breakpoint-down(sm) {
      &__list__item.news__item--featured {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
      }
    }

    @include media-breakpoint-between(lg, lg) {
      // @media (max-width: 960px) and (min-width: 768px) {
      &__list {
        grid-template-columns: repeat(4, 1fr);

        &__item:first-child {
          grid-row: 1/span 2;
        }
      }
    }

    @include media-breakpoint-between(lg, lg) {
      // @media (max-width: 1024px) and (min-width: 961px) {
      &__list {
        grid-template-columns: repeat(4, 1fr);

        &__item {
          grid-column: auto / span 1;
        }

        &__item:first-child {
          grid-column: 1/span 2;
        }
      }
    }
  }

  &__employees {
    @include media-breakpoint-down(md) {
      // @media (max-width: 960px) {
      .employee-card__crop {
        display: none;
      }

      .employee-card__content {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    @include media-breakpoint-between(lg, lg) {
      // @media (max-width: 1024px) and (min-width: 961px) {
      .col-md-24 {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }

    @include media-breakpoint-up(xl) {
      .col-xxl-5 {
        max-width: 20%;
        flex: 0 0 20%;
      }
    }
  }
}
