.press-project-card {
  position: relative;

  &__crop {
    .set--active &,
    .set--select & {
      opacity: .3;
    }

    &::before {
      padding-top: 75%;
      background-color: #fff;
    }
  }

  &__content__title {
    max-height: 33px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    color: inherit;
    transition: opacity .2s;
    text-transform: none;
    // margin-bottom: 0;
    margin: map-get($spacers, 2) 0;
    // padding: map-get($spacers, 1) 0;

    @include media-breakpoint-up(md) {
      //  padding: map-get($spacers, 2) 0;
    }
  }

  &__link,
  &__options {
    @include abs(0,0,0,0);
  }

  &__link {
    @include size(100%);

    pointer-events: all;
    display: block;
    font-size: 0;
    z-index: 50;
    color: transparent;
  }

  &__options {
    left: auto;
    bottom: auto;
    display: none;

    .set--select & {
      display: block;
    }
  }
}
