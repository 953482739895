.header {
  // &__crop {
  //   &::before {
  //     display: none;
  //   }
  // }
}

.content-page {
  &__title {
    text-transform: uppercase;
  }
}
