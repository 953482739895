.home-news {
  @include media-breakpoint-between(md, lg) {
    padding-left: map-get($spacers, 7);
    padding-right: map-get($spacers, 7);
    margin-bottom: map-get($spacers, 2);
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: map-get($spacers, 2);
    grid-auto-flow: dense;
    margin-bottom: 0;

    @include media-breakpoint-between(md, md) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &__item {
      margin-bottom: map-get($spacers, 2);

      @include media-breakpoint-between(md, md) {
        grid-column: auto / span 2;
        margin-bottom: map-get($spacers, 3);
        // &:nth-child(1) {
        //   grid-column: 1 / span 3;
        //   grid-row: 1 / span 2;
        // }
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9) {
          display: none;
        }
      }

      @include media-breakpoint-between(lg, lg) {
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9) {
          display: none;
        }
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: map-get($spacers, 3);

        &:nth-child(1) {
          grid-column: 1 / span 2;
          grid-row: 1 / span 2;

          .news-card__title {
            font-size: rem(20);
          }
        }
      }
    }
  }

  &__footer {
    @extend .spacer-top-s;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
