// Base

$default-font-size: 16px;
$rfs-minimum-font-size: rem($default-font-size); // always use rem unit
$rfs-base-font-size: rem($default-font-size); // always use rem unit
$transition-base: all .15s ease-in-out;
$font-size-base: strip-units($default-font-size); // overwrites default bootstrap variable with stripped font-size

// Body
$body-bg: $white;
$body-color: gray-color(900);

// Links
$link-color: theme-color(primary);
$link-decoration: underline;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

// Default font stack (used on <body>)
$font-family-sans-serif: 'GT America Medium', sans-serif;

// Headings
$headings-font-family: 'GT America Extended Bold', sans-serif;
$headings-font-family-secondary: 'GT America Medium', sans-serif;
$headings-font-weight: bold;
$headings-line-height: 1;
$headings-color: $black;

// Container
$text-muted: gray-color(300);

// Spacing
$spacer: 6px;
$spacers: (
  1: $spacer,       // 6  
  2: $spacer * 2,   // 12
  3: $spacer * 4,   // 24
  4: $spacer * 6,   // 36
  5: $spacer * 8,   // 48
  6: $spacer * 12,  // 72
  7: $spacer * 16,  // 96
  8: $spacer * 24,  // 144
  9: $spacer * 32   // 192
);

$container-max-widths: (
  xxl: 1600px,
  xl: 1276px,
  lg: 1140px,
  md: 1000px,
  xs: 960px,
  xxs: 912px,
);

// Form
$placeholder-font-family: $font-family-sans-serif;
$placeholder-color: gray-color(500);

// Printing
$print-page-size: a4;

