.block-content {
  &__quote {
    margin-bottom: map-get($spacers, 3);

    blockquote {
      // @extend .type-body-medium;
      @extend .h3;

      position: relative;
      line-height: 1.3;
      margin-bottom: map-get($spacers, 1);
      padding-left: 1ex;

      &::before,
      &::after {
        content: '"';
        display: inline-block;
      }

      &::after {
        transform: translateX(-.5ex);
      }

      &::before {
        position: absolute;
        transform: translateX(-1ex);
      }
    }

    figcaption {
      // &::before,
      // &::after {
      //   content: ' - ';
      // }
      padding-left: 1.32ex;
      font-size: .9em;
    }
  }

  &__text {
    @extend .type-body-small;

    margin-bottom: map-get($spacers, 5);

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &__title {
    @extend .spacer-bottom-xs;
    // @extend .type-body-medium;
  }
}
