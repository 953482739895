.slider-block {
  .slider__item__content {
    justify-content: center;

    .button {
      @include abs(null, null, map-get($spacers, 6), 50%);

      transform: translateX(-50%);
    }
  }

  &.set--inview .splitting .char {
    @extend %splitting-active;
  }

  &__title {
    @extend %splitting;
    @extend .header__content__title;
  }
}
