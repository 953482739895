.markdown-content {
  table {
    // margin-bottom: map-get($spacers, 4);
    width: 100%;

    td {
      padding: map-get($spacers, 2);
    }

    tr:nth-child(even) td {
      background: gray-color(100);
    }
  }

  blockquote {
    padding: map-get($spacers, 5) 0;

    @include get-font-size(h3-s);
    @include get-line-height(h3-s);

    @include media-breakpoint-up(sm) {
      @include get-font-size(h3-m);
      @include get-line-height(h3-m);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $headings-font-family-secondary;
    font-weight: normal;
    margin-bottom: map-get($spacers, 1);
  }

  iframe,
  object,
  embed {
    width: 100%;
    height: auto;
  }

  img {
    max-width: 100%;
  }

  em,
  i {
    font-style: italic;
  }

  @for $i from 1 through 6 {
    .h#{$i},
    h#{$i} {
      margin-top: map-get($spacers, 6);
    }
  }

  .fr-video {
    @extend .aspect-ratio;

    display: block;
    width: 100%;
    margin-bottom: map-get($spacers, 4);

    iframe {
      @extend .aspect-ratio-element;
    }
  }

  // blockquote {
  //   border-left: 2px solid $black;
  //   padding-left: map-get($spacers, 4);
  //   max-width: rem(700px);
  //   margin: map-get($spacers, 2) 0 map-get($spacers, 2) map-get($spacers, 4);

  //   &,
  //   * {
  //     font-size: map-get($font-sizes, body-medium);
  //   }
  // }

  p,
  ul,
  ol,
  dl {
    margin-bottom: map-get($spacers, 2);

    @include media-breakpoint-up(md) {
      margin-bottom: map-get($spacers, 4);
    }
  }

  ul,
  ol {
    list-style: outside;
    padding-inline-start: map-get($spacers, 3);

    li {
      display: list-item;
      list-style-type: initial;
    }
  }

  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style: none;
    position: relative;

    &::before {
      content: '-';
      position: absolute;
      // font-size: 9px;
      left: -2ex;
    }
  }
}
