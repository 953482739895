.image-caption {
  @include abs(null, null, 0, 0);
  @include size(100%, auto);

  padding: $spacer*0.5 $spacer 0;
  font-size: .8em;
  text-align: right;
  background-color: $white;
  // color: gray-color(500);

  // &::before,
  // &::after {
  //   content: ' - ';
  // }
}
