.select-field {
  position: relative;

  select {
    @include size(100%);

    position: absolute;
    outline: none;
    -webkit-appearance: none;
    will-change: background-color;
    font-size: 1rem;
  }

  .type--select {
    transform: scale(.7) translateY(-38px);
  }

  &__wrapper {
    height: 44px;
    position: relative;
    display: flex;

    fstr-icon {
      display: contents;
    }

    .icon {
      @include abs(50%, $spacer, null, null);
      @include size(20px);

      transform: translateY(-50%) rotate(180deg);
      pointer-events: none;
    }
  }
}
